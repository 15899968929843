<template>
  <v-dialog
    persistent
    :value="open"
    scrollable
    width="519px"
    attach="#main-container"
  >
    <v-card class="br-6 br-12">
      <v-card-text class="pa-4 pb-8">
        <v-row no-gutters>
          <v-col
            v-if="showCloseBtn"
            class="d-flex w-100 justify-space-between align-center"
          >
            <span class="h5 semiBold"></span>
            <v-btn v-if="!loading" @click="$emit('close')" icon>
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <!-- LOADING -->
          <v-col v-if="loading">
            <div class="d-flex flex-column align-center mt-2">
              <v-progress-circular
                :rotate="-60"
                :size="80"
                :width="10"
                indeterminate
                color="PRIMARY"
                class="primary--text"
              >
                <v-icon>mdi-file-outline</v-icon>
              </v-progress-circular>
              <span class="p mt-2" v-if="isUsedWithInspection">
                Estamos generando su propuesta</span
              >
              <span class="p mt-2" v-else> Estamos generando su póliza</span>

              <v-card class="light w-100" elevation="0">
                <v-card-text>
                  <ul>
                    <li>El envío del token se encuentra en progreso.</li>
                    <li>
                      Una vez verificado el token, la
                      <span v-if="isUsedWithInspection">propuesta</span
                      ><span v-else>póliza</span> será generada.
                    </li>
                    <li v-if="isUsedWithInspection">
                      Recuerda que está pendiente la aceptación de la
                      <b>inspección</b> para la <b>emisión de póliza.</b>
                    </li>
                    <li>
                      Podrá ver la información de su gestión una vez finalizado
                      el proceso.
                    </li>
                  </ul>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <!-- SUCCESS -->
          <v-col v-else-if="data?.success && !loading">
            <!-- <v-col v-else-if="true"> -->
            <div class="d-flex flex-column align-center">
              <v-icon x-large color="success" v-if="isUsedWithInspection"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon x-large v-else>mdi-check-circle</v-icon>

              <v-alert
                v-if="isUsedWithInspection"
                class="customSuccessAlert w-100 mt-2"
                colored-border
                border="left"
                type="success"
                icon="mdi-check-circle-outline"
              >
                <div>
                  <span class="p" v-if="isUsedWithInspection"
                    >Propuesta generada con éxito
                  </span>
                </div>
              </v-alert>
              <Alert v-else variant="success" class="w-100 mt-2">
                <template slot="content">
                  <div>
                    <span class="p">Póliza generada con éxito </span>
                  </div>
                </template>
              </Alert>

              <p v-if="!isUsedWithInspection">
                REF: <b>{{ data?.data?.poliza ?? "" }}</b>
              </p>

              <v-card class="light w-100" elevation="0">
                <v-card-text>
                  <template>
                    <li>El envío del token se encuentra en progreso.</li>
                    <li>
                      Una vez verificado el token, la
                      <span v-if="isUsedWithInspection">propuesta</span
                      ><span v-else>póliza</span> será generada.
                    </li>
                    <li v-if="isUsedWithInspection">
                      Recuerda que está pendiente la aceptación de la
                      <b>inspección</b> para la <b>emisión de póliza.</b>
                    </li>
                    <li>
                      Podrá ver la información de su gestión una vez finalizado
                      el proceso.
                    </li>
                  </template>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <!-- ERROR -->
          <v-col v-else>
            <div class="d-flex flex-column align-center m t-2">
              <v-icon x-large color="#B3261E ">mdi-alert-outline</v-icon>
              <v-card class="light br-12 w-100" elevation="0">
                <v-card-text>
                  <ul>
                    <li>
                      La <b>contratacion del seguro finalizó correctamente.</b>
                    </li>
                    <li v-if="isUsedWithInspection">
                      Recuérdale al cliente que
                      <b>para estar asegurado debe inspeccionar su vehículo</b>
                    </li>
                    <li v-else>
                      Indica al cliente que ya está <b>asegurado</b>
                    </li>
                  </ul>
                </v-card-text>
              </v-card>
              <v-alert
                v-if="!isUsedWithInspection"
                class="customErrorAlert w-100"
                colored-border
                border="left"
                type="error"
                icon="mdi-alert-outline"
              >
                <ul>
                  <li>
                    Estamos teniendo un <b>problema</b> en la
                    <b>emisión de la póliza</b>, estamos revisando la situación
                    y le
                    <b
                      >enviaremos al cliente su póliza por correo electrónico</b
                    >
                    a la brevedad posible.
                  </li>
                  <li class="mt-4">
                    Si tienes dudas o consultas llámanos a nuestros canales de
                    atención.
                  </li>
                </ul>
              </v-alert>
            </div>
          </v-col>
        </v-row>
        <template v-if="!loading">
          <v-row v-if="data?.success">
            <v-col>
              <div class="d-flex justify-space-around">
                <v-btn
                  color="primary"
                  rounded
                  class="text-none px-6"
                  width="203"
                  :outlined="isUsedWithInspection"
                  :disabled="!data?.data?.descargarPoliza"
                  @click="handlerDownloadDocument()"
                >
                  <span v-if="isUsedWithInspection">Descargar propuesta</span>
                  <span v-else>Descargar póliza</span>
                </v-btn>
                <v-btn
                  v-if="showBtnEntendido"
                  @click="$emit('close')"
                  rounded
                  color="primary"
                  width="203"
                  class="text-none px-6"
                >
                  Entendido
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <div class="d-flex justify-center">
                <v-btn
                  v-if="showBtnEntendido"
                  @click="$emit('close')"
                  rounded
                  color="primary"
                  class="text-none"
                  width="170"
                >
                  Entendido
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Alert from "@/components/Alert/Alert.vue";

import { downloadPdfFile } from "@/services/file.js";
import { mapGetters } from "vuex";

export default {
  name: "ModalContractSuccess",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      polizaSuccess: false,
      polizaError: false,
    };
  },

  components: {
    Alert,
  },
  methods: {
    handlerDownloadDocument() {
      const doc = this.data?.data?.documento_poliza ?? {};
      downloadPdfFile(
        doc?.data_archivo,
        doc?.tipo_archivo ?? "pdf",
        `${doc?.documento ?? "POL"}_${this.data?.data?.poliza ?? ""}`
      );
    },
  },
  computed: {
    ...mapGetters("Proposal", ["getProposalData"]),
    advanceInfo() {
      return this.$store.getters.getAdvanceInfo;
    },
    userChannelInfo() {
      return this.$store.getters.getGeneralInfo;
    },

    accesoPantallas() {
      return this.userChannelInfo?.personalizacion_corporativa
        ?.acceso_pantallas;
    },

    showBtnEntendido() {
      return (
        this.accesoPantallas?.find(
          (v) => v.seccion === "FIRMA_DIGITAL" && v.codigo === "BOTON_ENTENDIDO"
        )?.activo ?? true
      );
    },

    showCloseBtn() {
      return (
        this.accesoPantallas?.find(
          (v) =>
            v.seccion === "PROCESO_FIRMA" && v.codigo === "BOTON_CERRAR_MODAL"
        )?.activo ?? true
      );
    },

    isUsedWithInspection() {
      return (
        !this.getProposalData.cotizacion.nuevo &&
        this.advanceInfo.aplica_inspeccion
      );
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

.customErrorAlert {
  background: rgba(179, 38, 30, 0.1) !important;

  ul {
    font-size: 14px !important;
  }
}

.customSuccessAlert {
  background: rgba(6, 132, 77, 0.1);
}

:deep(.v-btn.v-btn--disabled .v-btn__content) {
  color: #000 !important;
}
</style>
