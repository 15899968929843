<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" offset-md="3">
        <h6>Ingrese Token para firmar los documentos</h6>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" offset-md="3">
        <!-- ALERTA DE CORREO -->
        <v-alert type="success" dense text border="left">
          <v-row align="center">
            <v-col class="grow">
              El Token ha sido enviado al email
              <b
                ><v-icon color="success">mdi-email</v-icon>
                {{ correo || "..." }}</b
              >
            </v-col>
            <v-col class="shrink">
              <v-btn
                text
                class="text-none"
                color="primary"
                @click="modalOpen = true"
              >
                <v-icon left>mdi-pencil</v-icon>
                Editar email
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <!--  -->
      </v-col>
      <v-col cols="12" md="6" offset-md="3">
        <v-card class="light py-6">
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" md="10" offset-md="1">
                <span
                  >Para finalizar la contratación ingrese el Token enviado al
                  email del cliente.</span
                >
                <div class="mt-5">
                  <LabelComponent text="Token*" />
                  <v-text-field
                    placeholder="XXXX-YYYY"
                    v-model="token"
                    v-mask="'NNNN-NNNN'"
                    outlined
                    dense
                    @keyup="token = token.toUpperCase()"
                    name="Token"
                  ></v-text-field>

                  <v-slide-y-transition mode="out-in">
                    <v-alert
                      v-if="error"
                      dense
                      type="error"
                      outlined
                      border="left"
                    >
                      {{ error }}
                    </v-alert>
                  </v-slide-y-transition>
                </div>
              </v-col>
              <v-col cols="12" md="10" offset-md="1">
                <a
                  @click="Firmar"
                  v-if="!loadingToken"
                  class="d-flex align-center mb-2 primary--text"
                  :class="[successToken && 'disabled']"
                >
                  <v-icon>mdi-repeat-variant</v-icon>
                  <span class="h5 ml-2 semibold">
                    Reenviar Token de verificación.
                  </span>
                </a>
                <a v-else class="disabled">
                  <v-icon>mdi-repeat-variant</v-icon>
                  <span class="h5 ml-2 semibold">
                    Reenviando Token de verificación...
                  </span>
                </a>
                <span v-if="timerCount > 0" class="mt-2">
                  Podrás reenviar el Token después de algunos segundos
                  <span class="ml-2">{{ timerCount }} </span>
                </span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              rounded
              color="primary "
              class="text-none"
              width="196"
              :disabled="!validForm"
              :loading="findClient || loadingToken"
              v-on:click.native="Continuar"
            >
              Terminar Contratación
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <ModalEditEmail
      v-model="modalOpen"
      :clientEmail="correo"
      sendOption
      @close="modalOpen = false"
      :ProposalNumber="UserInfo?.numero_propuesta"
      @submit="setCounter(), setSessionId($event)"
    />

    <ModalSuccess
      :open="processSuccess"
      @close="GoClientDetail"
      :data="tassData"
      :loading="processLoading"
    />
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import ModalEditEmail from "@/components/Modal/Modal-edit-email.vue";
import ModalSuccess from "@/components/Modal/Modal-contract-success.vue";
import LabelComponent from "@/components/Inputs/Label.vue";

export default {
  name: "Firma",
  components: {
    ModalEditEmail,
    LabelComponent,
    ModalSuccess,
  },
  props: {
    toggle: Function,
    UserInfo: {
      type: [Array, Object],
    },
    correoFirmaDig: {
      type: String,
    },
    session_id: {
      type: String,
    },
    propuesta: {
      type: Number,
    },
    loadingToken: {
      type: Boolean,
    },
  },
  data() {
    return {
      counter: 0,
      successToken: false,
      timerCount: -1,
      showTokenAlert: false,
      processSuccess: false,
      processLoading: false,
      error: "",
      //tokenCorrecto: "AA22-BB33",
      token: "",
      findClient: false,
      modalOpen: false,
      rules: [
        //value => value.length == 0 || "",
        (value) => !!value || "",
        (value) => {
          const pattern = /^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/;
          return pattern.test(value) || "Token inválido";
        },
      ],
      correo: "",
      sessionId: "",
      tassData: null,
    };
  },
  methods: {
    ...mapActions("Proposal", ["setSignatureToken"]),

    GoClientDetail() {
      this.$store.commit("setSnackbar", {
        active: true,
        text: "Su proceso de venta terminó exitosamente",
        top: true,
        right: true,
        color: "success",
      });

      const rut = this.UserInfo?.numero_documento;
      let route,
        params = {};
      if (rut) {
        route = "UserDetail";
        params = {
          rut,
        };
      } else {
        route = "dashboard";
      }
      this.$router.push({
        name: route,
        params: params,
      });
    },
    Continuar() {
      this.error = "";
      this.findClient = true;
      this.processLoading = true;
      this.processSuccess = true;
      this.setSignatureToken({
        data: {
          session_id: this.sessionId || this.session_id,
          token: this.token,
        },
        proposal: this.propuesta,
      })
        .then((response) => {
          this.tassData = response.result;
        })
        .catch((response) => {
          this.error =
            response.result?.data?.lista_mensaje?.[0]?.mensaje ??
            "Token ingresado no es correcto";
          this.processSuccess = false;
        })
        .finally(() => {
          this.processLoading = false;
          this.findClient = false;
        });
    },

    Firmar() {
      this.showTokenAlert = false;
      this.$emit("firma", true);
    },
    toggleModalOpen() {
      this.modalOpen = !this.modalOpen;
    },
    setCounter() {
      this.counter++;
      this.successToken = true;
      this.timerCount = 40;
      this.showTokenAlert = true;
    },
    setSessionId(id) {
      this.sessionId = id;
    },
  },

  created() {
    this.$parent.$on("successToken", () => {
      this.setCounter;
    });
    this.correo = this.correoFirmaDig;
  },
  computed: {
    validForm() {
      const pattern = /^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/;
      if (pattern.test(this.token)) {
        return this.token;
      }
      return false;
    },
  },
  watch: {
    token() {
      if (this.token == "") {
        this.error = "";
      }
      if (this.token.length < 9) {
        this.error = "";
      }
    },
    timerCount: {
      handler(value) {
        if (this.successToken) {
          if (value > 0) {
            setTimeout(() => {
              this.timerCount--;
            }, 1000);
          } else {
            this.successToken = false;
          }
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
};
</script>

<style scoped>
:deep(.disabled) {
  pointer-events: none !important;
  cursor: not-allowed;
  opacity: 0.6;
  color: #ccc !important;
}
</style>
