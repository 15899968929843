<template name="component-name">
  <div>
    <v-stepper tile flat v-model="selectedStep" elevation="0" height="120">
      <v-stepper-header>
        <template v-for="(item, index) in items">
          <v-stepper-step
            :step="index + 1"
            :complete="selectedStep > index + 1"
            :key="item.id"
            v-if="item.active"
          >
            <div
              class="d-flex flex-column align-center"
              :class="[
                selectedStep > index + 1
                  ? 'primary--text'
                  : '' || selectedStep === item.id
                  ? 'primary--text'
                  : 'unselected',
              ]"
            >
              <v-icon
                :class="selectedStep > index ? 'm-icon' : 'unselected'"
                :large="selectedStep === item.id"
                >{{ item.icon }}</v-icon
              >
              <span
                class="mt-2"
                :class="[
                  selectedStep > index + 1
                    ? 'primary--text'
                    : '' || selectedStep === item.id
                    ? 'primary--text bold'
                    : '',
                ]"
              >
                {{ item.label }}</span
              >
            </div>
          </v-stepper-step>
          <!-- eslint-disable-next-line vue/no-v-for-template-key -->
          <template v-if="index + 1 < items.length && item.active">
            <v-divider
              :key="`divider_${item.id}`"
              :class="[selectedStep > index + 1 && 'primary-border']"
            ></v-divider>
          </template>
        </template>
      </v-stepper-header>
    </v-stepper>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    selected: false,
  }),
  props: ["color", "selectedStep", "items"],
  computed: {
    ...mapGetters("Users", ["getIconoColor"]),
    cssVars() {
      return {
        "--bg-color": this.getIconoColor,
      };
    },
  },

  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },

  methods: {
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
  },
};
</script>

<style scoped>
:deep(.v-stepper__step__step) {
  display: none !important;
}

.v-stepper__header {
  box-shadow: none !important;
}

.theme--light.v-stepper {
  background-color: inherit;
}

.unselected,
.unselected .material-symbols-rounded {
  color: #cac4d0 !important;
}
</style>
