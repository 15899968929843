<template>
  <v-dialog
    @click:outside="$emit('close')"
    v-model="value"
    width="450"
    :persistent="loading"
    attach="#main-container"
    scrollable
  >
    <v-card>
      <v-card-title>
        Editar email del cliente
        <v-spacer></v-spacer>
        <v-btn fab x-small text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-4 pb-6">
        <v-row>
          <v-col cols="12">
            <v-form ref="emailForm">
              <LabelComponent text="Email*" />
              <v-text-field
                name="EditEmailModal"
                placeholder="Ingrese el correo electronico..."
                id="IdEditEmailModal"
                v-model="correo"
                outlined
                prepend-inner-icon="mdi-email-outline"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col>
            <v-alert type="warning" dense outlined border="left">
              <b>Importante:</b> <br />
              El nuevo email ingresado será el que aparecerá en la póliza y se
              actualizará en los datos del cliente.
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          outlined
          color="primary"
          class="text-none px-5"
          :disabled="loading"
          @click="$emit('close')"
          >Cancelar</v-btn
        >
        <v-btn
          rounded
          color="primary"
          class="text-none px-5"
          @click="handlerSetNewEmail"
          :loading="loading"
        >
          {{
            sendOption ? "Reenviar Token a nuevo email" : "Guardar nuevo email"
          }}
          <v-icon right>mdi-check-bold</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import LabelComponent from "@/components/Inputs/Label.vue";
import { validarEmail } from "@/store/resources/validate.js";
import { mapActions } from "vuex";
export default {
  props: {
    clientEmail: {
      type: String,
      default: "",
    },
    ProposalNumber: {
      type: [String, Number],
      default: "",
    },
    sendOption: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    clientEmail: function (newVal) {
      this.correo = newVal;
    },
  },
  data() {
    return {
      open: true,
      correo: "",
      loading: false,
      rules: {
        required: (value) => !!value || "Campo requerido.",
        email: (value) => validarEmail(value) || "Correo invalido.",
      },
    };
  },
  components: {
    LabelComponent,
  },
  methods: {
    ...mapActions("Proposal", ["updateProposalEmail", "setDigitalSignature"]),
    snackbar(message = "", type = "error") {
      this.$store.commit("setSnackbar", {
        active: true,
        text: message,
        top: true,
        right: true,
        color: type,
      });
    },
    handlerSetNewEmail() {
      const formValid = this.$refs.emailForm.validate();
      if (formValid) {
        this.loading = true;
        this.updateProposalEmail({
          id: this.ProposalNumber || "",
          email: this.correo,
        })
          .then(async (response) => {
            this.$emit("setDocs", response.data, response.email, false, true);
            this.correo = response.email;
            if (this.sendOption) {
              await this.handlerSetSignature();
            } else {
              this.snackbar(response.message, "success");
              this.$emit("close");
            }
          })
          .catch((message) => {
            this.snackbar(message);
          })
          .finally(() => (this.loading = false));
      }
    },
    async handlerSetSignature() {
      await this.setDigitalSignature(this.ProposalNumber)
        .then(({ result, info }) => {
          const sessionId = result.Propuesta.firma.session_id;
          this.$emit("submit", sessionId);
          this.snackbar(info, "success");
          this.$emit("close");
        })
        .catch((response) => {
          this.loading = false;
          this.snackbar(response.info);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
