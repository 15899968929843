<template>
  <div>
    <v-layout justify-center>
      <v-flex xs12 md8>
        <v-card class="light px-10 py-5" outlined>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" class="d-flex align-center justify-center">
                <v-icon large>mdi-credit-card</v-icon>
                <span class="h4 bold primary--text ml-3">PAT</span>
              </v-col>
              <v-col
                cols="12"
                class="d-flex align-center justify-center mb-5 my-4"
              >
                <span class="h5 semiBold">Pago Automático con Tarjeta</span>
              </v-col>
              <v-col class="d-flex justify-center">
                <v-card width="270" class="light" outlined>
                  <v-card-text class="px-12 py-6">
                    <span class="h5 primary--text bold">Tarjeta</span>
                    <v-layout class="mt-6">
                      <v-flex>
                        <h3 class="global-cardNumber">
                          **** **** **** **** {{ card_number[15]
                          }}{{ card_number[16] }}{{ card_number[17]
                          }}{{ card_number[18] }}
                        </h3>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex xs12>
                        <span
                          class="h6 semiBold"
                          v-if="expired_date.value === ''"
                        >
                          MM/YY
                        </span>
                        <h3 class="global-cardDate" v-else>
                          {{ expired_date.value }}
                        </h3>
                      </v-flex>
                    </v-layout>
                    <v-layout justify-space-between align-end class="mr-2 mb-2">
                      <v-flex>
                        <p class="mb-0 pl-2 caption text-left">
                          {{ bank.value ? bank.value.banco_descripcion : "" }}
                        </p>
                      </v-flex>
                      <!-- <v-flex xs2>
                        <v-img
                          class="global-imgTwo"
                          v-if="card_number[0] === '5'"
                          src="@/assets/svg/mc_logo.svg"
                        ></v-img>
                        <v-img
                          class="global-img8 mt-5"
                          v-else
                          src="@/assets/svg/frame_v.svg"
                        ></v-img>
                      </v-flex> -->
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="mt-5">
                <v-form ref="cardForm">
                  <v-row multi-line>
                    <v-col cols="6" class="px-6">
                      <LabelComponent text="Número de tarjeta*" />
                      <v-text-field
                        name="credit-card-number"
                        id="id"
                        v-mask="masks.cardMask"
                        outlined
                        dense
                        max_length="19"
                        :rules="[rules.number]"
                        v-model="card_number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="px-6">
                      <!-- CAMPO DATE CON CALENDARIO -->
                      <!-- <DatePicker
                  label="Fecha de vencimiento"
                  color="#0077c8"
                  type="month"
                  :date="expired_date"
                  @input="expired_date = $event"
                  :placeholder="'MM/YY'"
                  :min="true"
                /> -->
                      <!-- CAMPO DATE SIN CALENDARIO -->
                      <LabelComponent :text="`${expired_date.label}*`" />
                      <v-text-field
                        :placeholder="expired_date.placeholder"
                        outlined
                        dense
                        :rules="expired_date.rules"
                        v-model="expired_date.value"
                        v-mask="masks.cardDateMask"
                        return-masked-value
                        name="numero_vencimiento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="px-6">
                      <LabelComponent :text="`${bank.label}*`" />
                      <v-select
                        :items="bankList"
                        v-model="bank.value"
                        :item-text="bank.itemText"
                        :placeholder="bank.placeholder"
                        return-object
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        :loading="bank.loading"
                        :rules="bank.rules"
                        name="banco"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="px-6">
                      <LabelComponent :text="`${payday.label}*`" />
                      <v-select
                        :items="tarifa.dias_pago || payday.defaultOptions"
                        v-model="payday.value"
                        :placeholder="payday.placeholder"
                        :rules="payday.rules"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        mandatory
                        name="dias_pago"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-alert
              type="error"
              class="mr-3"
              :value="createPATError"
              dismissible
              outlined
            >
              {{ PATErrorMessage }}
            </v-alert>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mx-1 text-none"
              outlined
              width="140"
              rounded
              @click="goBack"
              :disabled="loading"
              name="boton_volver_PAT"
              >Volver</v-btn
            >
            <v-btn
              color="primary"
              class="text-none"
              rounded
              width="140"
              @click="goNext()"
              :loading="loading"
              name="boton_continuar_PAT"
              >Continuar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import DatePicker from "@/components/Inputs/DatePicker";
import { mapActions, mapGetters } from "vuex";
import { cardDateMask, cardMask } from "@/helpers/mask.js";
import {
  validarFechaMMYY,
  validarFechaExpiracion,
  validarNumeroTarjeta,
} from "@/store/resources/validate.js";
import LabelComponent from "@/components/Inputs/Label.vue";
import { dateToNumber } from "@/store/resources/calc.js";
export default {
  props: {
    payId: {
      type: String,
      default: "",
      description: "Id del medio de pago seleccionado",
    },
  },
  components: {
    LabelComponent,
  },
  data() {
    return {
      loading: false,
      card_number: "",
      createPATError: false,
      PATErrorMessage: "",
      expired_date: {
        label: "Fecha de expiración",
        placeholder: "mm/yy",
        value: "",
        rules: [
          (v) =>
            !!v || "Debe especificar la fecha de expiración de la tarjeta.",
          (v) => v.length == 5 || "Escriba el mes y el año.",
          (v) => validarFechaMMYY(v) || "Formato de fecha incorrecto.",
          (v) => validarFechaExpiracion(v) || "La tarjeta ha expirado.",
          (v) => validarFechaExpiracion(v, 3) || "Tarjeta próxima a expirar.",
        ],
      },
      bank: {
        label: "Banco Emisor",
        itemText: "banco_descripcion",
        placeholder: "Seleccione el banco de emisión",
        value: null,
        loading: false,
        rules: [(v) => !!v || "Debe seleccionar un Banco."],
      },
      payday: {
        label: "Día de pago",
        value: "",
        placeholder: "Seleccione el día de facturación",
        defaultOptions: [5, 15, 25],
        rules: [(v) => !!v || "Debe seleccionar un día de pago."],
      },
      rules: {
        number: (value) => validarNumeroTarjeta(value) || "Tarjeta invalida",
      },
    };
  },
  // components: {
  //   DatePicker,
  // },
  methods: {
    ...mapActions("Payment", ["REQUEST_BANK_LIST"]),
    ...mapActions("Proposal", ["newClientPayment"]),
    goBack() {
      this.$emit("back", true);
    },

    goNext() {
      const isValid = this.$refs.cardForm.validate();
      if (isValid) {
        this.loading = true;
        const numbercardList = this.card_number.split(" ");
        const dataPAT = {
          id: this.getProposalData?.numero_propuesta,
          medio_pago: "PAT",
          tipo_medio_pago: "PAT",
          id_medio_de_pago: this.payId || "",
          numero_tarjeta: numbercardList.join(""),
          fecha_vencimiento: dateToNumber(this.expired_date.value),
          dia_pago: this.payday.value.toString(),
          banco: this.bank.value,
        };
        this.createPAT(dataPAT);
      }
    },
    createPAT(data) {
      this.createPATError = false;
      this.newClientPayment(data)
        .then(() => {
          this.$emit("next", true);
        })
        .catch((error) => {
          this.createPATError = true;
          this.PATErrorMessage = error;
        })
        .finally(() => (this.loading = false));
    },
    getBankList() {
      this.bank.loading = true;
      this.REQUEST_BANK_LIST().finally(() => (this.bank.loading = false));
    },
  },
  created() {
    this.getBankList();
  },
  computed: {
    ...mapGetters("Payment", ["bankList"]),
    ...mapGetters("Proposal", ["getProposalData", "tarifa"]),
    masks: () => {
      return { cardDateMask, cardMask };
    },
  },
};
</script>
