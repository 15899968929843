<template>
  <v-dialog persistent v-model="open" width="850" attach="#main-container">
    <v-card class="br-6">
      <v-card-title primary-title>
        {{ getItemShow?.documento_descripcion ?? "" }}
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close" text x-small fab>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-layout align-center justify-center>
          <v-flex
            xs10
            v-if="
              getItemShow.tipo_archivo === 'image' ||
              getItemShow.tipo_archivo === 'img'
            "
          >
            <v-img
              class="global-image-fit"
              :src="ImageFile"
              v-if="ImageFile !== null"
            ></v-img>
          </v-flex>
          <v-flex xs12 v-else :style="{ height: '500px' }">
            <embed
              v-if="!loading"
              width="100%"
              height="500"
              frameborder="0"
              :src="ImageFile"
            />
            <div v-else class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  props: ["document"],
  data() {
    return {
      open: true,
      file_type: null,
      url_pdf: "",
      loading: false,
      error: false,
    };
  },
  methods: {
    ...mapActions("Payment", ["CloseShowModal"]),
    close() {
      this.CloseShowModal({ data: false });
    },
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
  },
  computed: {
    ...mapGetters("Payment", ["getItemShow"]),
    ImageFile() {
      return (
        this.url_pdf ??
        (this.getItemShow?.archivo
          ? URL.createObjectURL(this.getItemShow.archivo)
          : "")
      );
    },
  },
  mounted() {
    this.loading = true;
    if (this.document) {
      this.file_type = this.document.tipo_archivo;
      axios
        .get(this.document?.url_achivo)
        .then((res) => {
          console.log("res", res);

          // this.url_pdf = res.data.data.url;
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      this.file_type = this.getItemShow.tipo_archivo;

      if (this.getItemShow?.url_archivo) {
        axios.get(this.getItemShow?.url_archivo).then((res) => {
          this.url_pdf = res.data.data.url;
        });
      } else {
        this.url_pdf = this.b64toBlob(this.getItemShow?.data_archivo) || "";
      }
    }
    this.loading = false;
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

:deep(.v-dialog) {
  overflow: hidden !important;
}
</style>
