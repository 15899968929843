<template>
  <div>
    <v-layout justify-center>
      <v-flex xs12 md8>
        <v-card class="light px-10 py-5" outlined>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" class="d-flex align-center justify-center">
                <v-icon large>mdi-wallet</v-icon>
                <span class="h4 bold primary--text ml-3">PAC</span>
              </v-col>
              <v-col
                cols="12"
                class="d-flex align-center justify-center mb-5 mt-2"
              >
                <span class="h5 semiBold mt-2">Pago Automático de Cuentas</span>
              </v-col>
              <v-col cols="12" md="6" class="px-6">
                <LabelComponent :text="`${bank.label}*`" />
                <v-select
                  :items="bankList"
                  v-model="bank.value"
                  :item-text="bank.itemText"
                  :placeholder="bank.placeholder"
                  return-object
                  outlined
                  dense
                  :loading="bank.loading"
                  append-icon="mdi-chevron-down"
                  name="banco"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6" class="px-6">
                <LabelComponent text="Tipo de cuenta*" />
                <SelectLabel
                  :fields="getPAC.cuentas"
                  color="#0077c8"
                  name="cuenta_PAC"
                />
              </v-col>

              <v-col cols="12" md="6" class="px-6">
                <LabelComponent text="Número de cuenta*" />
                <v-text-field
                  placeholder="Ingrese el número de cuenta"
                  v-model="card_number"
                  outlined
                  dense
                  v-mask="[numberMask]"
                  name="numero_cuenta"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" class="px-6">
                <LabelComponent :text="`${payday.label}*`" />
                <v-select
                  :items="tarifa.dias_pago || payday.defaultOptions"
                  v-model="payday.value"
                  :placeholder="payday.placeholder"
                  :rules="payday.rules"
                  outlined
                  dense
                  mandatory
                  append-icon="mdi-chevron-down"
                  name="dias_pago"
                ></v-select>
              </v-col>
            </v-row>
            <div class="mb-6 d-flex"></div>
          </v-card-text>
          <v-card-actions>
            <v-alert
              class="mr-3"
              type="error"
              :value="createPACError"
              dismissible
              outlined
            >
              {{ PACErrorMessage }}
            </v-alert>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              rounded
              class="mx-1 text-none"
              outlined
              width="140"
              @click="goBack"
              name="boton_volver_PAC"
              >Volver</v-btn
            >
            <v-btn
              color="primary"
              class="text-none"
              rounded
              width="140"
              @click="goNext"
              :disabled="!validForm"
              :loading="loading"
              name="boton_continuar_PAC"
              >Continuar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import SelectLabel from "@/components/Inputs/SelectLabel";
import { mapActions, mapGetters } from "vuex";
import LabelComponent from "@/components/Inputs/Label.vue";
import { onlyNumberMask } from "@/helpers/mask.js";

export default {
  props: {
    payId: {
      type: String,
      default: "",
      description: "Id del medio de pago seleccionado",
    },
    setTitle: String,
  },
  data() {
    return {
      loading: false,
      createPACError: false,
      PACErrorMessage: "",
      card_number: "",
      bank: {
        label: "Banco",
        itemText: "banco_descripcion",
        placeholder: "Seleccione el banco",
        value: null,
        loading: false,
        rules: [(v) => !!v || "Debe seleccionar un Banco."],
      },
      payday: {
        label: "Día de pago",
        value: "",
        placeholder: "Seleccione el día de facturación",
        defaultOptions: [5, 15, 25],
        rules: [(v) => !!v || "Debe seleccionar un día de pago."],
      },
    };
  },
  components: {
    SelectLabel,
    LabelComponent,
  },
  created() {
    this.getBankList();
    this.$emit("setTitle");
  },
  methods: {
    ...mapActions("Payment", ["REQUEST_BANK_LIST"]),
    ...mapActions("Proposal", ["newClientPayment"]),

    getBankList() {
      this.loadingBank = true;
      this.REQUEST_BANK_LIST().finally(() => (this.loadingBank = false));
    },
    goBack() {
      this.$emit("back", true);
    },

    goNext() {
      const dataPAC = {
        id: this.getProposalData?.numero_propuesta,
        tipo_medio_pago: "PAC",
        medio_pago: "PAC",
        banco: this.bank.value,
        tipo_cuenta: this.getPAC.cuentas.selected,
        numero_cuenta: this.card_number,
        id_medio_de_pago: this.payId || "",
        dia_pago: this.payday.value,
      };

      this.createPAC(dataPAC);
    },

    createPAC(data) {
      this.loading = true;
      this.createPACError = false;
      this.newClientPayment(data)
        .then(() => {
          this.$emit("next", true);
        })
        .catch((error) => {
          this.createPACError = true;
          this.PACErrorMessage = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters("Payment", ["getPAC", "bankList"]),
    ...mapGetters("Proposal", ["getProposalData", "tarifa"]),
    ...mapGetters("Users", ["getTituloColor"]),
    validForm() {
      return (
        this.card_number &&
        this.bank.value &&
        this.payday.value &&
        this.getPAC.cuentas.selected
      );
    },
    numberMask: () => onlyNumberMask(30),
  },
};
</script>
