var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form"},[_c('div',{staticClass:"pt-10"},[_c('v-layout',{staticClass:"mt-1",attrs:{"justify-center":""}},[_c('v-flex',{staticClass:"mr-4",attrs:{"xs6":""}},[_c('LabelComponent',{attrs:{"text":"Marca*"}}),_c('v-text-field',{staticClass:"pt-0",attrs:{"name":"marca","disabled":"","value":_vm.goodData.marca_descripcion}})],1),_c('v-flex',{staticClass:"ml-4",attrs:{"xs6":""}},[_c('LabelComponent',{attrs:{"text":"Modelo*"}}),_c('v-text-field',{staticClass:"pt-0",attrs:{"value":_vm.goodData.modelo_descripcion,"disabled":"","name":"modelo"}})],1)],1),_c('v-layout',{staticClass:"mt-1",attrs:{"justify-center":""}},[_c('v-flex',{staticClass:"mr-4",attrs:{"xs6":""}},[_c('LabelComponent',{attrs:{"text":"Año*"}}),_c('v-text-field',{staticClass:"pt-0",attrs:{"value":_vm.goodData.anio,"disabled":"","name":"goodYear"}})],1),_c('v-flex',{staticClass:"ml-4",attrs:{"xs6":""}},[_c('LabelComponent',{attrs:{"text":`${_vm.lang?.patent ?? ''}*`}}),_c('v-text-field',{staticClass:"pt-0",attrs:{"value":_vm.goodData.patente === 'FACTURA' ? 'En Trámite' : _vm.goodData.patente,"disabled":"","name":"patente"}})],1)],1),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{staticClass:"mr-4",attrs:{"xs6":""}},[_c('LabelComponent',{attrs:{"text":"Número de motor*"}}),_c('v-text-field',{attrs:{"placeholder":"Escribe el número del motor...","rules":_vm.form.venta === 'V'
              ? []
              : [_vm.rules.required, _vm.rules.min7, _vm.rules.alphaNum],"name":"numero_motor","onkeypress":`return ${_vm.alphaNum()}`,"outlined":"","dense":"","maxlength":"20"},on:{"keyup":function($event){_vm.form.numero_motor = _vm.form.numero_motor.toUpperCase()}},model:{value:(_vm.form.numero_motor),callback:function ($$v) {_vm.$set(_vm.form, "numero_motor", $$v)},expression:"form.numero_motor"}})],1),_c('v-flex',{staticClass:"ml-4",attrs:{"xs6":""}},[_c('LabelComponent',{attrs:{"text":"Número de chasis*"}}),_c('v-text-field',{attrs:{"placeholder":"Escribe el número del chasis...","rules":_vm.form.venta === 'V'
              ? []
              : [_vm.rules.required, _vm.rules.min17, _vm.rules.alphaNum],"name":"numero_chasis","onkeypress":`return ${_vm.alphaNum()}`,"outlined":"","dense":"","maxlength":"20"},on:{"keyup":function($event){_vm.form.numero_chasis = _vm.form.numero_chasis.toUpperCase()}},model:{value:(_vm.form.numero_chasis),callback:function ($$v) {_vm.$set(_vm.form, "numero_chasis", $$v)},expression:"form.numero_chasis"}})],1)],1),_c('v-layout',[_c('v-flex',{staticClass:"mr-4",attrs:{"xs6":"","name":"flex_name"}},[_c('LabelComponent',{attrs:{"text":"Inicio de vigencia*"}}),_c('v-menu',{ref:"menu",attrs:{"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.rules.required, _vm.rules.date, _vm.rules.dateRange],"placeholder":"DD/MM/AAAA","outlined":"","dense":"","name":"inicio_vigencia","append-icon":"mdi-calendar"},model:{value:(_vm.form.inicio_vigencia),callback:function ($$v) {_vm.$set(_vm.form, "inicio_vigencia", $$v)},expression:"form.inicio_vigencia"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"max":new Date(
                Date.now() +
                  60 * 24 * 60 * 60 * 1000 -
                  new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .substring(0, 10),"min":new Date(
                Date.now() -
                  12 * 60 * 60 * 1000 -
                  new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .substring(0, 16)},on:{"change":_vm.handlerSaveDate},model:{value:(_vm.form.inicio_vigencia),callback:function ($$v) {_vm.$set(_vm.form, "inicio_vigencia", $$v)},expression:"form.inicio_vigencia"}})],1)],1),_c('v-flex',{staticClass:"ml-4",attrs:{"xs6":"","name":"flex_name"}},[_c('LabelComponent',{attrs:{"text":"Color*"}}),_c('v-select',{attrs:{"items":_vm.getColorsData,"placeholder":"Seleccione un color","outlined":"","rules":[_vm.rules.required],"dense":"","item-text":"descripcion","item-value":"codigo","hide-details":"","mandatory":"","name":"color"},model:{value:(_vm.form.color),callback:function ($$v) {_vm.$set(_vm.form, "color", $$v)},expression:"form.color"}})],1)],1),(_vm.ventaVerde)?_c('v-layout',[_c('v-flex',{staticClass:"ml-4 align-self-center",attrs:{"xs6":""}},[_c('v-checkbox',{attrs:{"label":"Venta verde","true-value":'V',"false-value":'N'},model:{value:(_vm.form.venta),callback:function ($$v) {_vm.$set(_vm.form, "venta", $$v)},expression:"form.venta"}})],1)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }