<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <h6>Complete los requisitos solicitados:</h6>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <Alert variant="info">
          <template slot="content"
            >Tipo de vehículo: <b>{{ newCar ? "NUEVO" : "USADO" }}</b></template
          >
        </Alert>
        <Alert v-if="!newCar" :variant="needInspection ? 'warning' : 'success'">
          <template slot="content">
            <span
              >Todo vehículo usado requiere ser <b>inspeccionado</b> o que
              <b>adjunte un documento de entrega</b>
            </span>
          </template>
        </Alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-card class="light" outlined min-height="350">
          <v-card-text class="px-8 py-6">
            <v-row v-if="!newCar && selected !== 'inspect'">
              <v-col>
                <p class="text-center">
                  ¿Desea realizar inspección o adjuntar un documento de entrega?
                </p>
              </v-col>
            </v-row>
            <v-row class="pt-4 pb-8" v-if="selected !== 'inspect' && !newCar">
              <div class="w-100 d-flex justify-space-around align-center">
                <v-card
                  class="hoverable typeCard light"
                  @click="selectType('inspect')"
                  :class="selected === 'inspect' ? 'selected' : ''"
                >
                  <v-card-text class="py-6">
                    <div class="d-flex flex-column align-center">
                      <v-icon>mdi-magnify-scan</v-icon>
                      <span class="h4 primary--text mt-3">Inspeccionar</span>
                    </div>
                  </v-card-text>
                </v-card>
                <v-card
                  class="hoverable typeCard light"
                  @click="selectType('document')"
                  :class="selected === 'document' ? 'selected' : ''"
                >
                  <v-card-text class="py-6">
                    <div class="d-flex flex-column align-center">
                      <v-icon name="upload">mdi-upload</v-icon>
                      <span class="h4 primary--text mt-3"
                        >Adjuntar documento</span
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-row>
            <v-row v-if="selected === '' && !newCar">
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="mx-1 text-none"
                    outlined
                    rounded
                    @click="back()"
                    :disabled="loading"
                    width="140"
                    name="boton_volver"
                  >
                    Volver
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <v-fade-transition>
              <div v-if="selected === 'document' || newCar">
                <DocumentForm
                  :newCar="newCar"
                  :proposalNumber="proposal"
                  :needInspection="needInspection"
                  :needDocument="needDocument"
                  :channel="channel"
                  :data="data.documentos"
                  :green-sale="greenSale"
                  @next="next()"
                  @back="back()"
                />
              </div>
              <div v-else-if="selected === 'inspect'">
                <Inspection
                  @next="next()"
                  @back="back()"
                  :inspect-companies="inspeccionesList"
                  :UserInfo="data"
                  :needDocument="needDocument"
                  :newCar="newCar"
                />
              </div>
            </v-fade-transition>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ModalFecha v-if="showDateModal" :close="handleDateModal" />
  </v-container>
</template>

<script>
import Alert from "@/components/Alert/Alert.vue";
import ModalFecha from "@/components/Modal/Modal-fecha-factura.vue";
import DocumentForm from "@/components/User-Components/Contract/RequirementStep/DocumentForm.vue";
import Inspection from "@/components/User-Components/Contract/RequirementStep/InspectionStep.vue";

import StepMixin from "@/Mixins/ContractStepMixin.js";

export default {
  name: "ChileRequirements",
  mixins: [StepMixin],
  props: {
    newCar: {
      type: Boolean,
      default: false,
      description: "Indicador para definir si el automovil es nuevo",
    },
    needInspection: {
      type: Boolean,
      default: false,
      description: "Indicador para definir si la propuesta amerita inspeccion",
    },
    needDocument: {
      type: Boolean,
      default: false,
      description:
        "Indicador para definir si la propuesta amerita que el usuario adjunte un documento de factura",
    },
    inspeccionesList: {
      type: Array,
      default: () => [],
      description: "Listado de inspecciones",
    },
    greenSale: {
      type: Boolean,
      default: false,
    },
  },
  components: { Alert, ModalFecha, DocumentForm, Inspection },
  data: () => ({
    selected: "",
    showDateModal: false,
  }),
  methods: {
    selectType(selected) {
      this.selected = selected;
    },
  },
};
</script>
