<template>
  <v-form ref="form">
    <div class="pt-10">
      <v-layout class="mt-1" justify-center>
        <v-flex xs6 class="mr-4">
          <LabelComponent text="Marca*" />
          <v-text-field
            name="marca"
            disabled
            :value="goodData.marca_descripcion"
            class="pt-0"
          ></v-text-field>
        </v-flex>
        <v-flex xs6 class="ml-4">
          <LabelComponent text="Modelo*" />
          <v-text-field
            :value="goodData.modelo_descripcion"
            disabled
            name="modelo"
            class="pt-0"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout class="mt-1" justify-center>
        <!-- ANIO -->
        <v-flex xs6 class="mr-4">
          <LabelComponent text="Año*" />
          <v-text-field
            :value="goodData.anio"
            disabled
            name="goodYear"
            class="pt-0"
          ></v-text-field>
        </v-flex>
        <!-- PATENTE -->
        <v-flex xs6 class="ml-4">
          <LabelComponent :text="`${lang?.patent ?? ''}*`" />
          <v-text-field
            :value="
              goodData.patente === 'FACTURA' ? 'En Trámite' : goodData.patente
            "
            disabled
            name="patente"
            class="pt-0"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout justify-center>
        <!-- NUMERO DE MOTOR -->
        <v-flex xs6 class="mr-4">
          <LabelComponent text="Número de motor*" />
          <v-text-field
            placeholder="Escribe el número del motor..."
            v-model="form.numero_motor"
            :rules="
              form.venta === 'V'
                ? []
                : [rules.required, rules.min7, rules.alphaNum]
            "
            name="numero_motor"
            :onkeypress="`return ${alphaNum()}`"
            outlined
            dense
            @keyup="form.numero_motor = form.numero_motor.toUpperCase()"
            maxlength="20"
          ></v-text-field>
        </v-flex>
        <!-- NUMERO DE CHASIS -->
        <v-flex xs6 class="ml-4">
          <LabelComponent text="Número de chasis*" />
          <v-text-field
            placeholder="Escribe el número del chasis..."
            v-model="form.numero_chasis"
            :rules="
              form.venta === 'V'
                ? []
                : [rules.required, rules.min17, rules.alphaNum]
            "
            name="numero_chasis"
            :onkeypress="`return ${alphaNum()}`"
            outlined
            dense
            @keyup="form.numero_chasis = form.numero_chasis.toUpperCase()"
            maxlength="20"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs6 class="mr-4" name="flex_name">
          <!-- INICIO VIGENCIA FIELD -->
          <LabelComponent text="Inicio de vigencia*" />
          <v-menu
            ref="menu"
            v-model="menu"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.inicio_vigencia"
                :rules="[rules.required, rules.date, rules.dateRange]"
                placeholder="DD/MM/AAAA"
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                name="inicio_vigencia"
                append-icon="mdi-calendar"
              ></v-text-field>
            </template>
            <v-date-picker
              @change="handlerSaveDate"
              v-model="form.inicio_vigencia"
              :max="
                new Date(
                  Date.now() +
                    60 * 24 * 60 * 60 * 1000 -
                    new Date().getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .substring(0, 10)
              "
              :min="
                new Date(
                  Date.now() -
                    12 * 60 * 60 * 1000 -
                    new Date().getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .substring(0, 16)
              "
            ></v-date-picker>
          </v-menu>
        </v-flex>

        <!-- // COLOR FIELD -->
        <v-flex xs6 class="ml-4" name="flex_name">
          <LabelComponent text="Color*" />
          <v-select
            :items="getColorsData"
            v-model="form.color"
            placeholder="Seleccione un color"
            outlined
            :rules="[rules.required]"
            dense
            item-text="descripcion"
            item-value="codigo"
            hide-details
            mandatory
            name="color"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout v-if="ventaVerde">
        <v-flex xs6 class="ml-4 align-self-center">
          <v-checkbox
            label="Venta verde"
            v-model="form.venta"
            :true-value="'V'"
            :false-value="'N'"
          ></v-checkbox>
        </v-flex>
      </v-layout>
    </div>
  </v-form>
</template>

<script>
import LabelComponent from "@/components/Inputs/Label.vue";
import {
  validarMinStringLimit,
  validarAlphaNum,
  validarFecha,
  validarFechaRango,
} from "@/store/resources/validate.js";
import { dateMask } from "@/helpers/mask.js";
import { alphaNumericKeys } from "@/store/resources/calc.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "GoodCarForm",
  props: {
    proposalData: {
      type: Object,
      default: () => {},
    },
    ventaVerde: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    LabelComponent,
  },
  data: () => ({
    loadingColors: false,
    menu: false,
    date: "",
    form: {
      marca: undefined,
      modelo: undefined,
      anio: "",
      patente: "",
      fecha_factura: undefined,
      interes_asegurable: false,
      numero_factura: "",
      numero_motor: "",
      numero_chasis: "",
      color: "",
      venta: "",
      inicio_vigencia: "",
    },
    rules: {
      required: (value) => !!value || "Campo requerido.",
      min7: (value) => validarMinStringLimit(value, 7),
      min17: (value) => validarMinStringLimit(value, 17),
      alphaNum: (value) => validarAlphaNum(value),
      date: (value) => validarFecha(value) || "Formato de fecha incorrecto",
      dateRange: (value) =>
        validarFechaRango(value, 60) ||
        "Fecha no válida (no puede ser menor a hoy o mayor a 60 días)",
    },
  }),
  created() {
    this.setup();
  },
  computed: {
    ...mapGetters("Proposal", ["getColorsData"]),
    goodData() {
      const { cotizacion, datos_bien, venta } = this.proposalData;
      const inicioVigencia =
        new Date(datos_bien?.inicio_vigencia ?? "") ?? null;
      return {
        marca: cotizacion?.obj_marca?.marca ?? "",
        marca_descripcion: cotizacion?.obj_marca?.marca_descripcion ?? "",
        modelo: cotizacion?.obj_modelo?.modelo ?? "",
        modelo_descripcion: cotizacion?.obj_modelo?.modelo_descripcion ?? "",
        anio: cotizacion?.anio ?? "",
        patente: cotizacion?.patente ?? "",
        color: datos_bien?.color ?? "",
        venta: venta ?? datos_bien?.venta ?? "N",
        fecha_factura: datos_bien?.fecha_factura ?? "",
        numero_motor: datos_bien?.numero_motor ?? "",
        numero_chasis: datos_bien?.numero_chasis ?? "",
        interes_asegurable:
          this.proposalData?.tarifa_seleccionada?.interes_asegurable ?? false,
        inicio_vigencia: datos_bien
          ? `${String(inicioVigencia.getDate()).padStart(2, "0")}/${String(
              inicioVigencia.getMonth() + 1
            ).padStart(2, "0")}/${inicioVigencia.getFullYear()}`
          : "",
      };
    },
    alphaNum() {
      return alphaNumericKeys;
    },
    dateMask: () => dateMask,
  },
  methods: {
    ...mapActions("Proposal", ["getColors"]),
    setup() {
      // == Obtener listado de colores
      this.setColors();
      // == Rellenar datos del formulario
      this.setFormData();
    },
    setFormData() {
      this.form.marca = this.goodData.marca;
      this.form.modelo = this.goodData.modelo;
      this.form.anio = this.goodData.anio;
      this.form.patente = this.goodData.patente;
      this.form.fecha_factura = this.goodData.fecha_factura;
      this.form.interes_asegurable = this.goodData.interes_asegurable;
      this.form.numero_factura = this.goodData.numero_factura;
      this.form.numero_motor = this.goodData.numero_motor;
      this.form.numero_chasis = this.goodData.numero_chasis;
      this.form.color = this.goodData.color;
      this.form.venta = this.goodData.venta;
      this.form.inicio_vigencia = this.goodData.inicio_vigencia;
    },
    setColors() {
      this.loadingColors = true;
      this.getColors().finally(() => {
        this.loadingColors = false;
      });
    },
    handlerSaveDate(date) {
      const [year, month, day] = date.split("-");
      this.form.inicio_vigencia = `${day}/${month}/${year}`;
    },
  },
};
</script>
