var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h6',[_vm._v("Complete los datos que faltan del asegurado")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8","offset":"2"}},[_c('v-card',{staticClass:"bordered br-12",attrs:{"outlined":"","height":_vm.tarifa?.interes_asegurable && _vm.tab === 'tab-1' ? '790' : '700'}},[_c('div',{staticClass:"card-content"},[_c('v-card-text',[_c('v-container',[_c('div',{staticClass:"borderBottom d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pb-2 px-0",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.tab = 'tab-1'}}},[_c('span',{staticClass:"py-2 px-5 h6",class:[
                        _vm.formError.insured
                          ? 'error--text'
                          : _vm.tab === 'tab-1'
                          ? 'primary--text item-borderStyle'
                          : '',
                      ]},[_vm._v(" Asegurado ")])]),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"pb-2 px-0 align-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.tab = 'tab-2'}}},[(_vm.showBenefitTab)?_c('span',{staticClass:"py-2 px-5 h6",class:[
                          _vm.formError.benefic
                            ? 'error--text'
                            : _vm.tab === 'tab-2'
                            ? 'primary--text item-borderStyle'
                            : '',
                        ]},[_vm._v(" Beneficiado ")]):_vm._e()]),(_vm.showBenefitTab)?_c('v-icon',{staticClass:"pb-2",attrs:{"small":""},on:{"click":function($event){return _vm.showBenefit(1)}}},[_vm._v("mdi-close")]):_vm._e()],1)]),(!_vm.showBenefitTab && _vm.isChile)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showBenefit(0)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',{staticClass:"primary--text ml-2 h6",style:({ color: _vm.titleColor })},[_vm._v(" Agregar persona ")])],1):_vm._e()]),_c('div',{staticClass:"pt-10"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab === 'tab-1'),expression:"tab === 'tab-1'"}]},[(_vm.isChile)?_c('Insured',_vm._b({},'Insured',_vm.bindFormData,false)):(_vm.isPeru)?[(_vm.client?.tipo_persona == 'JUR')?_c('InsuredJURPeru',_vm._b({},'InsuredJURPeru',_vm.bindFormData,false)):_c('InsuredNATPeru',_vm._b({},'InsuredNATPeru',_vm.bindFormData,false))]:_vm._e()],2),_c('Benefict',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab === 'tab-2'),expression:"tab === 'tab-2'"}],ref:"beneficForm",attrs:{"form-data":_vm.data?.beneficiarios?.[1] ?? null}})],1)])],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }