<template>
  <v-card class="light h-100" elevation="2" style="cursor: pointer">
    <div
      class="d-flex align-center primary--text justify-center h-100 py-10 px-4"
    >
      <v-icon large>mdi-plus</v-icon>
      <span class="h3 primary--text bold ml-3">Otro</span>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "NewPaymentCard",
};
</script>
