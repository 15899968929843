<template>
  <div class="text-center">
    <v-dialog
      v-model="open"
      width="60%"
      persistent
      name="dialog_datos_faltantes"
    >
      <v-card class="global-modalCard5">
        <v-layout wrap class="pa-0">
          <v-flex xs10 class="text-left ml-8 pt-10" style="z-index: 10">
            <h1 class="global-text5">Confirmación Firma Manual</h1>
            <!-- <v-spacer></v-spacer> -->
            <p class="mt-5 global-subtitle-modal">
              ¿Realizaste la descarga, impresión y <br />firma manual de los
              documentos al cliente?
            </p>
            <v-layout class="mt-8">
              <div
                id="ComputerSvg"
                class="ml-0"
                style="align-items: center; justify-content: center"
              >
                <v-img
                  class="image-cuate"
                  width="200px"
                  src="@/assets/modalImages/cuate.png"
                  contain
                />
              </div>
            </v-layout>
          </v-flex>
          <v-img
            width="400px"
            id="BackgroundSvgs"
            src="@/assets/modalImages/Vector.png"
          />

          <v-flex class="d-flex align-center justify-center ml-5 mt-14">
            <v-btn
              color="primary"
              class="mr-6 pr-6 pl-6 mt-5"
              width="214"
              height="41"
              :loading="loading"
              @click="GoClientDetail()"
              >Si</v-btn
            >
            <v-btn
              color="primary"
              class="mr-6 pr-6 pl-6 mt-5"
              width="214"
              height="41"
              outlined
              @click="$emit('cerrarModal')"
              >No</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
//import { mapGetters } from "vuex";
//import SelectLabel from "@/components/Inputs/SelectLabel.vue";

export default {
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    numero_propuesta: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    GoClientDetail() {
      this.loading = true;
      axios({
        url: `propuestas/${this.numero_propuesta}/firma/manual`,
        method: "POST",
      })
        .then(() => {
          setTimeout(() => {
            this.$router
              .push({
                name: "dashboard",
              })
              .catch(() => {});
          }, 3000);
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: "Error al terminar la cotización",
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: "Cotización Finalizada, recuerde firmar sus documentos.",
            top: true,
            right: true,
            color: "success",
          });
          this.loading.MAN = false;
        });
    },
  },
  computed: {},
  components: {},
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

h4 {
  z-index: 10;
}

.image-cuate {
  position: absolute;
  top: 30%;
  right: 5%;
  max-width: 500px;
  z-index: 0;
}

#BackgroundSvgs {
  position: absolute;
  top: 0%;
  right: 0%;
  max-width: 500px;
}

#ComputerSvg {
  z-index: 10;
}

:deep() .v-dialog {
  border-radius: 4px;
  margin: 24px;
  overflow: hidden;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
}
</style>
