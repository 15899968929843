<template>
  <v-card
    class="h-100 cursor-pointer light card-selectable global-cardStyle2"
    elevation="5"
  >
    <div class="d-flex pa-5 flex-column">
      <v-icon x-large>mdi-plus</v-icon>
      <span v-if="isFirst" class="h4 primary--text bold"
        >Agregar un nuevo medio de pago</span
      >
      <span v-else class="h4 primary--text bold">Usar otro medio de pago</span>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "RegisterPaymentButtonCard",
  props: {
    isFirst: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
