<template>
  <div>
    <chile-reqs v-if="isChile" v-bind="bindReqsData" />
    <peru-reqs v-else-if="isPeru" v-bind="bindReqsData" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChileReqs from "./RequirementStep/ChileReqs.vue";
import PeruReqs from "./RequirementStep/PeruReqs.vue";

import StepMixin from "@/Mixins/ContractStepMixin.js";
import CountryMixin from "@/Mixins/CountryMixin.js";
export default {
  name: "ContractRequirements",
  mixins: [StepMixin, CountryMixin],
  components: {
    ChileReqs,
    PeruReqs,
  },
  props: {
    newCar: {
      type: Boolean,
      default: false,
      description: "Indicador para definir si el automovil es nuevo",
    },
    needInspection: {
      type: Boolean,
      default: false,
      description: "Indicador para definir si la propuesta amerita inspeccion",
    },
    needDocument: {
      type: Boolean,
      default: false,
      description:
        "Indicador para definir si la propuesta amerita que el usuario adjunte un documento de factura",
    },
    inspeccionesList: {
      type: Array,
      default: () => [],
      description: "Listado de inspecciones",
    },
  },
  computed: {
    ...mapGetters("Proposal", ["getGreenSale"]),
    bindReqsData() {
      return {
        ...this.$props,
        country: this.country,
        greenSale: this.getGreenSale,
      };
    },
  },
};
</script>
