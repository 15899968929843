<template>
  <v-card
    class="hoverable typeCard customMargin light"
    :class="selected && 'selected'"
    style="cursor: pointer"
    :loading="loading"
    name="contract_card"
  >
    <v-card-text>
      <div class="d-flex flex-column align-center">
        <v-icon
          :class="userData.icon"
          class="global-iconStyle3 primary--text"
          large
        ></v-icon>

        <span class="h4 primary--text mt-3">{{ userData.nombre }}</span>
      </div>
    </v-card-text>

    <template slot="progress">
      <v-progress-linear height="5" indeterminate></v-progress-linear>
    </template>
  </v-card>
</template>
<script>
export default {
  props: ["userData", "loading", "selected"],
};
</script>
<style scoped>
.customMargin {
  margin: 0 !important;
}
</style>
