<template>
  <v-container>
    <v-layout justify-center class="mt-2">
      <v-flex xs12 v-if="PaymentMethods.length > 4">
        <v-slide-group show-arrows v-model="slideGroup">
          <v-slide-item
            v-for="(item, i) in PaymentMethods"
            :key="i"
            v-show="!item.type"
          >
            <template #default>
              <v-flex xs3 class="mx-2">
                <Card
                  :cardData="item"
                  @mpay-refresh="handlerRefreshMpay"
                  @mpay-cancel="handlerCancelMpay"
                  @click.native="handlerClickPayment(item)"
                  :loading="item.loading"
                  :mpayLoading="mpayLoading"
                  :loadingCancel="mpayLoadingCancel"
                  :cardHeight="190"
                  :disabled="cardDisableCondition(item)"
                />
              </v-flex>
            </template>
          </v-slide-item>
          <v-slide-item v-if="!blocked">
            <template #default>
              <v-flex xs3 class="mx-2">
                <NewPayment
                  :isFirst="!(getPaymentsMethods.length > 1)"
                  v-on:click.native="show"
                />
              </v-flex>
            </template>
          </v-slide-item>
        </v-slide-group>
      </v-flex>
      <v-flex xs12 v-else-if="PaymentMethods.length > 1">
        <v-layout justify-center>
          <template v-for="(item, index) in getPaymentsMethods">
            <v-flex v-if="!item.type" xs3 class="mx-2" :key="index">
              <Card
                :cardData="item"
                @mpay-refresh="handlerRefreshMpay"
                @mpay-cancel="handlerCancelMpay"
                @click.native="handlerClickPayment(item)"
                :loading="item.loading"
                :mpayLoading="mpayLoading"
                :loadingCancel="mpayLoadingCancel"
                :cardHeight="190"
                :disabled="cardDisableCondition(item)"
              />
            </v-flex>
            <v-flex
              v-else-if="!blocked"
              xs3
              class="mx-2"
              :key="'nerpaument_' + index"
            >
              <NewPayment
                :isFirst="!(getPaymentsMethods.length > 1)"
                v-on:click.native="show"
              />
            </v-flex>
          </template>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-else>
        <v-layout justify-center wrap>
          <v-flex xs4 class="mx-2 justify-center">
            <NewPayment
              :isFirst="!(getPaymentsMethods.length > 1)"
              class="ml-7"
              v-on:click.native="show"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout justify-center class="mb-10 mt-15">
      <v-flex xs6 class="text-center">
        <v-btn
          color="primary"
          rounded
          outlined
          @click="goBack"
          width="140"
          class="mr-2 text-none"
          name="boton_volver"
          >Volver</v-btn
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  paymentStatusCodes,
  paymentMethodsCodes,
} from "@/store/resources/payments.js";
import Card from "@/components/Cards/Card-card.vue";
import NewPayment from "@/components/Cards/New-payment-card.vue";
import axios from "axios";
export default {
  name: "ClientPaymentStep",
  props: ["start", "next", "blocked", "proposalNumber"],
  components: {
    Card,
    NewPayment,
  },
  data: () => ({
    mpayLoading: false,
    mpayLoadingCancel: false,
    slideGroup: 0,
  }),
  computed: {
    ...mapGetters("Proposal", ["getPaymentsMethods"]),
    PaymentMethods() {
      return this.getPaymentsMethods;
    },
    statusCodes: () => paymentStatusCodes,
  },
  methods: {
    ...mapMutations("Proposal", ["setPaymentStatus"]),
    ...mapActions("Payment", ["SEND_PAYMENT"]),

    goBack() {
      this.$emit("back", true);
    },

    show() {
      this.$emit("start", true);
    },
    handlerClickPayment(pay) {
      // Al hacer clic no hacer nada.
      if (
        // Condicion de bloqueado con mpay2
        (this.blocked &&
          !(
            pay.codigo === paymentMethodsCodes[6] ||
            pay.codigo === paymentMethodsCodes[7]
          )) ||
        // Condicion de mpay1 y aprobado
        (pay.codigo === paymentMethodsCodes[5] &&
          pay.codigo_estado !== paymentStatusCodes[2])
      ) {
        return false;
        // Emitir funcion de mpay2
      } else if (
        pay.codigo === paymentMethodsCodes[6] ||
        pay.codigo === paymentMethodsCodes[7]
      ) {
        // Si no esta en caso rechazado, proceder.
        if (
          !(
            pay.codigo_estado === paymentStatusCodes[3] ||
            pay.estado === paymentStatusCodes[3] ||
            pay.codigo_estado === paymentStatusCodes[5] ||
            pay.estado === paymentStatusCodes[5]
          )
        ) {
          this.$emit("mpay2", pay);
        }

        return false;
      }
      // Seleccionar el resto de medios de pago
      pay.loading = true;
      const payload = {
        id: this.proposalNumber,
        data: pay,
      };
      this.SEND_PAYMENT(payload)
        .then(() => {
          this.goNext();
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (pay.loading = false));
    },
    handlerRefreshMpay() {
      this.mpayLoading = true;
      axios
        .get(`/medio-pagos/mpay/${this.proposalNumber}/estado`)
        .then((response) => {
          const result = response.data.data.result;
          const estado = result.estado;
          this.setPaymentStatus(estado);
        })
        .finally(() => (this.mpayLoading = false));
    },
    handlerCancelMpay() {
      this.mpayLoadingCancel = true;
      axios
        .delete(`/medio-pagos/mpay/${this.proposalNumber}/dar-de-baja`, {
          data: {
            motivo: "Dar de baja",
          },
        })
        .then((response) => {
          const result = response.data.data.result;
          const estado = result.estado;
          this.setPaymentStatus(estado);
          this.$emit("cancelMpay");
        })
        .finally(() => (this.mpayLoadingCancel = false));
    },
    goNext() {
      this.next();
    },
    cardDisableCondition(item = null) {
      let condition = false;
      if (this.blocked) {
        condition = true;
        if (
          item.codigo_estado === paymentStatusCodes[1] ||
          item.estado === paymentStatusCodes[1] ||
          item.codigo_estado === paymentStatusCodes[2] ||
          item.estado === paymentStatusCodes[2]
        ) {
          condition = false;
        }
        if (
          item.codigo === paymentMethodsCodes[8] ||
          item.codigo === paymentMethodsCodes[5]
        ) {
          condition = true;
        }
      }
      if (
        item.codigo_estado === paymentStatusCodes[3] ||
        item.estado === paymentStatusCodes[3] ||
        item.codigo_estado === paymentStatusCodes[5] ||
        item.estado === paymentStatusCodes[5]
      ) {
        condition = true;
      }
      return condition;
    },
  },
};
</script>
