<template>
  <v-form ref="form" class="mt-10">
    <v-row>
      <v-col cols="12" md="6">
        <LabelComponent text="Comuna*" />
        <v-autocomplete
          :items="cities"
          item-text="label"
          v-model="form.comuna"
          :rules="[rules.required]"
          name="comuna_bien"
          outlined
          dense
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <LabelComponent text="Dirección*" />
        <v-text-field
          name="direccion_aseg"
          outlined
          v-model="form.direccion"
          dense
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <LabelComponent text="Número*" />
        <v-text-field
          name="numero_bien"
          v-model="form.numero"
          dense
          outlined
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <LabelComponent text="Departamento/Casa" />
        <v-text-field
          name="piso_bien"
          v-model="form.departamento"
          dense
          outlined
        ></v-text-field>
      </v-col>
    </v-row>

    <p class="mb-3">
      <v-btn
        color="primary"
        class="text-none"
        small
        text
        @click="handleFillForm"
      >
        <v-icon left>mdi-pencil-plus-outline</v-icon>Rellenar con datos del
        asegurado.
      </v-btn>
    </p>
  </v-form>
</template>

<script>
import LabelComponent from "@/components/Inputs/Label.vue";
import { mapGetters } from "vuex";
export default {
  name: "GoodHomeForm",
  props: {
    proposalData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    LabelComponent,
  },
  data: () => ({
    form: {
      comuna: "",
      direccion: "",
      numero: "",
      departamento: "",
    },
    rules: {
      required: (val) => !!val || "Campo requerido",
    },
  }),
  created() {
    this.setup();
  },
  computed: {
    ...mapGetters("Proposal", ["getCities"]),
    cities() {
      return this.getCities?.answers ?? [];
    },
  },
  methods: {
    setup() {
      const goodData = this.proposalData?.datos_bien;
      this.form.comuna = goodData?.comuna ?? "";
      this.form.direccion = goodData?.direccion ?? "";
      this.form.numero = goodData?.numero ?? "";
      this.form.departamento = goodData?.departamento ?? "";
    },
    handleFillForm() {
      const insuredData = this.proposalData?.asegurado;
      this.form.comuna = insuredData?.comuna ?? "";
      this.form.direccion = insuredData?.direccion ?? "";
      this.form.numero = insuredData?.numero ?? "";
      this.form.departamento = insuredData?.piso ?? "";
    },
  },
};
</script>
