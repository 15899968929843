<template>
  <v-dialog :value="value" width="450" attach="#main-container">
    <v-card>
      <v-card-title>
        ¡Oops! Fecha desfasada
        <v-spacer></v-spacer>
        <v-btn fab x-small text @click="$emit('close')" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert type="error" icon="mdi-calendar-remove" outlined>
          <div>
            <span class="p"
              >La
              <span class="semiBold">fecha del documento</span>
              es anterior a la permitida para considerar el cero kilómetro (48
              horas de antigüedad)</span
            >
          </div>
        </v-alert>

        <ul>
          <li>
            Por favor,
            <span class="semiBold">revise la fecha del documento</span>
            o bien
            <span class="semiBold">adjunte otro documento</span> que respalde la
            condición de vehículo nuevo y cuya fecha no supere las 48 horas a la
            fecha actual (por ejemplo: factura, guía de despacho, acta de
            entrega, etc.)
          </li>
        </ul>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          @click="$emit('close')"
          color="primary"
          rounded
          class="text-none px-6"
          >Entendido</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "FechaModalInfo",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
