<template>
  <div class="d-flex flex-column">
    <div class="d-flex align-center">
      <v-icon name="warning" color="error">mdi-alert</v-icon>
      <span class="error-text ml-1 semiBold">ADVERTENCIA</span>
    </div>
    <span>
      A partir de este punto, ya no se podrá modificar esta propuesta.
    </span>
  </div>
</template>
<script>
export default {
  name: "WarningAlert",
};
</script>
