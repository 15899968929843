<template>
  <div class="mt-8">
    <span>
      Para continuar con la inspección a en el local, <b>confirme</b> los
      siguientes datos:
    </span>
    <v-form ref="form" class="mt-4">
      <v-row no-gutters class="justify-center">
        <v-col cols="12">
          <LabelComponent text="Dirección particular o comercial*" />
          <TextField :fields="Address" name="direccion" />
          <LabelComponent text="Nombre de contacto*" />
          <TextField :fields="Contact" name="contacto" />
          <LabelComponent text="Teléfono de contacto*" />
          <TextFieldPhone
            :fields="Phone.text"
            @input="Phone.text = $event"
            :rules="Phone.rules"
            name="telefono"
          />
        </v-col>
      </v-row>
    </v-form>
    <div class="my-6">
      <Warning />
    </div>
    <div class="mt-8 text-center">
      <v-btn
        color="primary"
        class="mx-1 text-none"
        outlined
        rounded
        @click="handleBack()"
        :disabled="loading"
        width="140"
        name="boton_volver"
        >Volver</v-btn
      >
      <v-btn
        color="primary"
        rounded
        @click="HandleInspectLocal()"
        class="mx-1 text-none"
        name="boton_continuar_local"
        width="140"
        :loading="loadingBtn"
        >Continuar</v-btn
      >
    </div>

    <SuccessModal
      SuccessPath="contratacion"
      :open="successDialog"
      :propuesta="UserInfo.numero_propuesta"
      @ActionButton="handleContinue()"
      alert_message="<span class='p'>El cliente recibirá un <span class='bold'>e-mail</span> con los próximos pasos para la inspección en el local.</v-btn>"
    />
  </div>
</template>

<script>
import axios from "axios";
import LabelComponent from "@/components/Inputs/Label.vue";
import Warning from "@/components/User-Components/Contract/Warning.vue";
import SuccessModal from "@/components/Modal/Modal-Success";
import TextFieldPhone from "@/components/Inputs/TextFieldPhone";

export default {
  name: "LocalInspect",
  props: ["selectedInspectType", "selectedInspectCompany", "UserInfo"],
  data() {
    return {
      loadingBtn: false,
      successDialog: false,
      Address: {
        text: "",
        ref: "origin",
        rules: [(v) => !!v || "Dirección del domicilio es requerido"],
      },

      Contact: {
        text: "",
        rules: [(v) => !!v || "Contacto es requerido"],
      },

      Phone: {
        text: "",
        tipo: "phone",
        rules: [(v) => !!v || "Teléfono es requerido"],
      },
    };
  },
  components: { LabelComponent, Warning, SuccessModal, TextFieldPhone },
  methods: {
    handleBack() {
      this.$emit("back");
    },
    handleContinue() {
      this.$emit("next");
    },
    HandleInspectLocal() {
      if (this.$refs.form.validate()) {
        const user = this.UserInfo;
        this.loadingBtn = true;
        const phone = this.Phone.text.replace(/[^A-Z0-9]/gi, "");
        axios({
          url: `propuestas/${user.numero_propuesta}/inspeccion`,
          method: "POST",
          data: {
            requiere_inspeccion: true,
            id_inspeccion: this.selectedInspectType._id,
            nombre_inspeccion: this.selectedInspectType.nombre,
            id_empresa: this.selectedInspectCompany.id_empresa,
            nombre_empresa: this.selectedInspectCompany.nombre_empresa,
            tipo_inspeccion: "IEL",
            direccion_local: this.Address.text,
            contacto: this.Contact.text,
            telefono: phone,
          },
        })
          .then(() => {
            this.$refs.form.reset();
            this.successDialog = true;
          })
          .finally(() => (this.loadingBtn = false));
      }
    },
  },
};
</script>
