<template>
  <div>
    <v-layout justify-center class="pb-10">
      <v-flex class="text-center">
        <h3 class="global-titleStyle18" :style="{ color: titleColor }">
          Para crear la propuesta completa los datos
        </h3>
        <h3 class="global-subStyle2">faltantes del cliente en cuestión.</h3>
      </v-flex>
    </v-layout>

    <v-layout justify-center class="mt-15">
      <v-flex xs5>
        <v-layout justify-center>
          <v-flex xs6 class="mr-4" name="flex_banco">
            <SelectLabel :fields="getPAC.bancos" color="#0077c8" name="banco"
          /></v-flex>
          <v-flex xs6 class="ml-4" name="flex_cuenta">
            <SelectLabel :fields="getPAC.cuentas" color="#0077c8" name="cuenta"
          /></v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout justify-center>
      <v-flex xs5>
        <v-layout justify-start>
          <v-flex xs6 class="pr-4"
            ><Input
              label="Número de cuenta"
              placeholder="Número de cuenta"
              color="#0077C8"
              readonly="false"
              :hide="false"
              :model="card_number"
              @input="card_number = $event"
              max_length="19"
              v-mask="'#### #### #### ####'"
              :rules="[rules.required]"
              name="numero_cuenta"
          /></v-flex>
          <v-flex xs6 class="pl-4" name="flex_dias_pago">
            <SelectLabel :fields="getPAC.dias" color="#0077c8" name="dias_pago"
          /></v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout justify-center class="mt-2" v-if="documents.length === 0">
      <v-flex xs5 class="d-flex justify-end" @click="onButtonClick">
        <label for="file_input_id" class="files" name="adjuntar">
          <v-icon size="22px" color="#0077C8">mdi-paperclip</v-icon
          ><span class="global-input">Adjuntar</span></label
        >
        <input
          ref="uploader"
          class="d-none"
          type="file"
          @change="onFileChanged"
          name="subir_doc"
        />
      </v-flex>
    </v-layout>
    <v-layout justify-center v-if="documents.length > 0" class="mt-5">
      <v-flex xs5>
        <v-layout align-center>
          <v-flex>
            <h3 class="global-counterStyle">
              Archivos adjuntos ({{ documents.length }})
            </h3></v-flex
          >
          <v-flex class="text-end"
            ><v-btn
              @click="onButtonClick"
              text
              class="global-adjuntar text-capitalize blue--text"
              name="boton_adj_cedula"
            >
              <v-icon small>mdi-paperclip</v-icon>
              Adjuntar cédula
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              @change="onFileChanged"
              name="subir_cedula"
          /></v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout justify-center class="mt-1" v-if="documents.length > 0">
      <v-flex xs5>
        <SimpleTable
          :headers="headers_doc"
          :items="documents"
          :DownloadButton="downDownloadbtn"
          :ShowButton="showbtn"
        />
      </v-flex>
    </v-layout>
    <v-layout justify-center class="mb-10 mt-5">
      <v-flex xs5 class="text-end">
        <SButton
          color="#ddecf7"
          label="Volver"
          width="155"
          height="45"
          v-on:click.native="goBack"
          name="boton_volver"
        />
        <PButton
          class="ml-2"
          :loading="loading"
          label="Continuar"
          width="155"
          height="45"
          v-on:click.native="HandleMedioPago"
          :disabled="!validForm"
          name="boton_continuar"
        />
      </v-flex>
    </v-layout>
    <ModalFile v-if="openModal" />
  </div>
</template>
<script>
import Input from "@/components/Inputs/Input";
import SelectLabel from "@/components/Inputs/SelectLabel";
import ModalFile from "@/components/Modal/Modal-show-file.vue";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  props: ["next", "numero_propuesta"],
  data() {
    return {
      card_number: "",
      expired_date: "",
      loading: false,
      documents: [],
      rules: {
        required: (val) => {
          return val.length === 19 || "Cuenta inválida";
        },
      },
      headers_doc: [
        {
          text: "Nombre",
          value: "archivo_descripcion",
          width: "20%",
        },
        {
          text: "Tipo",
          value: "documento_descripcion",
          width: "10%",
          align: "center",
        },
        {
          text: "Tamaño",
          value: "size_archivo",
          align: "center",
          width: "10%",
        },
        {
          text: "Fecha",
          value: "fecha_creacion_ci",
          width: "20%",
          align: "center",
        },
        {
          text: "Acción",
          value: "ci_action",
          width: "10%",
          align: "center",
        },
      ],
    };
  },
  components: {
    Input,
    SelectLabel,
    ModalFile,
  },
  methods: {
    ...mapActions("Payment", ["createPAT", "OpenShowModal"]),
    downDownloadbtn(item) {
      if (item.archivo) {
        const blob = new Blob([item.archivo], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = item.archivo_descripcion;
        link.click();
      }
    },

    showbtn(item) {
      this.OpenShowModal({ item: item });
    },
    goBack() {
      this.$emit("back", true);
    },
    onButtonClick() {
      window.addEventListener("focus", () => {}, { once: true });

      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      this.documents = [
        {
          archivo_descripcion: e.target.files[0].name,
          documento_descripcion: "cédula",
          size_archivo: e.target.files[0].size,
          fecha_creacion_ci: new Date(),
          archivo: e.target.files[0],
        },
      ];

      // do something
    },

    HandleMedioPago() {
      if (this.validForm && this.documents.length !== 0) {
        this.loading = true;
        axios({
          url: `propuestas/${this.getProposalData.numero_propuesta}/medio-pago/cuenta-vista`,
          method: "POST",
          data: {
            medio_pago: "CV",
            banco: this.getPAC.bancos.selected,
            tipo_cuenta: this.getPAC.cuentas.selected,
            numero_cuenta: this.card_number,
            data_archivo: this.documents[0].archivo,
            dia_pago: this.getPAC.dias.selected,
          },
        }).then(() => {
          this.loading = false;
          this.$emit("next");
        });
      }
    },
  },
  computed: {
    ...mapGetters("Payment", ["getPAC", "getShowModal"]),
    ...mapGetters("Proposal", ["getProposalData"]),
    ...mapGetters("Users", ["getTituloColor"]),
    openModal() {
      return this.getShowModal;
    },
    validForm() {
      return (
        this.card_number &&
        this.getPAC.bancos.selected &&
        this.getPAC.cuentas.selected &&
        this.documents.length !== 0
      );
    },
    titleColor() {
      if (this.getTituloColor != null) {
        return this.getTituloColor;
      } else {
        return "#0077c8";
      }
    },
  },
};
</script>
<style scoped>
@import "../../../../../assets/CSS/ServiceStyle.css";

#file_input_id {
  display: none;
}
</style>
