<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <h6>Adjunte y complete los requisitos solicitados:</h6>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <Alert variant="info">
          <template slot="content"
            >Tipo de vehículo: <b>{{ newCar ? "NUEVO" : "USADO" }}</b></template
          >
        </Alert>
      </v-col>
      <v-col cols="12" md="8" offset-md="2" v-if="!newCar">
        <v-card class="light pa-10" outlined>
          <v-row>
            <v-col cols="12">
              <!-- Documento adjunto -->
              <LabelComponent text="Adjuntar tarjeta de propiedad" />
              <v-file-input
                outlined
                dense
                prepend-icon="mdi-box"
                @change="handlerSelectFile($event, 'adicional')"
                accept="application/pdf"
                name="adj_doc_adicional"
              >
              </v-file-input>
            </v-col>
            <v-col cols="12">
              <!-- Documento adjunto -->
              <LabelComponent text="Adjuntar comprobante de GPS" />
              <v-file-input
                outlined
                dense
                prepend-icon="mdi-box"
                @change="handlerSelectFile($event, 'adicional')"
                accept="application/pdf"
                name="adj_doc_adicional"
              >
              </v-file-input>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
        offset-md="2"
        v-if="!newCar"
        :variant="needInspection ? 'warning' : 'success'"
      >
        <Alert variant="warning">
          <template slot="content">
            <span
              >El canal requiere que el vehículo sea <b>inspeccionado</b> o que
              se
              <b>adjunte un documento de entrega</b>
            </span>
          </template>
        </Alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-card class="light pa-10" outlined>
          <v-row v-if="!newCar && selected !== 'inspect'">
            <v-col>
              <p class="text-center">
                ¿Desea realizar inspección o adjuntar un documento de entrega?
              </p>
            </v-col>
          </v-row>
          <v-row class="pt-4 pb-8" v-if="selected !== 'inspect' && !newCar">
            <div class="w-100 d-flex justify-space-around align-center">
              <v-card
                class="hoverable typeCard light"
                @click="selectType('inspect')"
                :class="selected === 'inspect' ? 'selected' : ''"
              >
                <v-card-text class="py-6">
                  <div class="d-flex flex-column align-center">
                    <v-icon>mdi-magnify-scan</v-icon>
                    <span class="h4 primary--text mt-3">Inspeccionar</span>
                  </div>
                </v-card-text>
              </v-card>
              <v-card
                class="hoverable typeCard light"
                @click="selectType('document')"
                :class="selected === 'document' ? 'selected' : ''"
              >
                <v-card-text class="py-6">
                  <div class="d-flex flex-column align-center">
                    <v-icon>mdi-upload</v-icon>
                    <span class="h4 primary--text mt-3"
                      >Adjuntar documento</span
                    >
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-row>
          <v-row v-if="selected === '' && !newCar">
            <v-col>
              <div class="d-flex justify-center">
                <v-btn
                  color="primary"
                  class="mx-1 text-none"
                  outlined
                  rounded
                  @click="back()"
                  :disabled="loading"
                  width="140"
                  name="boton_volver"
                >
                  Volver
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <v-fade-transition>
            <div v-if="selected === 'inspect'">
              <Inspection
                @next="next"
                @back="back()"
                :inspect-companies="advanceInfo.inspecciones"
                :UserInfo="data"
                :needDocument="advanceInfo.requiere_documento_venta"
                :newCar="newCar"
              />
            </div>
            <div v-else-if="selected === 'document' || newCar">
              <PeruNewCarForm
                @next="next"
                @back="back()"
                :proposalNumber="proposal"
                :needInspection="needInspection"
                :newCar="newCar"
                :needDocument="needDocument"
                :greenSale="greenSale"
              />
            </div>
          </v-fade-transition>
        </v-card>
      </v-col>
    </v-row>
    <ModalFecha v-if="showDateModal" :close="handleDateModal" />
  </v-container>
</template>

<script>
import Alert from "@/components/Alert/Alert.vue";
import ModalFecha from "@/components/Modal/Modal-fecha-factura.vue";
import PeruNewCarForm from "@/components/User-Components/Contract/RequirementStep/DocumentPeruForm.vue";
import Inspection from "@/components/User-Components/Contract/RequirementStep/InspectionPeru.vue";
import { mapGetters } from "vuex";
import LabelComponent from "@/components/Inputs/Label.vue";

import StepMixin from "@/Mixins/ContractStepMixin.js";

export default {
  name: "PeruRequirements",
  mixins: [StepMixin],
  props: {
    newCar: {
      type: Boolean,
      default: false,
      description: "Indicador para definir si el automovil es nuevo",
    },
    needInspection: {
      type: Boolean,
      default: false,
      description: "Indicador para definir si la propuesta amerita inspeccion",
    },
    needDocument: {
      type: Boolean,
      default: false,
      description:
        "Indicador para definir si la propuesta amerita que el usuario adjunte un documento de factura",
    },
    channel: {
      type: String,
      default: "",
      description: "Nombre del canal",
    },
    greenSale: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Alert,
    ModalFecha,
    Inspection,
    LabelComponent,
    PeruNewCarForm,
  },
  data: () => ({
    selected: "",
    showDateModal: false,
  }),
  computed: {
    ...mapGetters("Proposal", [
      "getProposalData",
      "getBeneficts",
      "getProposalGood",
      "getStep",
    ]),
    advanceInfo() {
      return this.$store.getters.getAdvanceInfo;
    },
  },
  methods: {
    selectType(selected) {
      this.selected = selected;
    },
  },
};
</script>
