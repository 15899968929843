<template>
  <div>
    <v-form ref="form">
      <v-layout>
        <!-- CAMPO RUT -->
        <v-flex xs6 class="mr-4">
          <LabelComponent text="Rut*" />
          <v-text-field
            v-model="form.numero_documento"
            disabled
            class="pt-0"
            name="rut_aseg"
            v-mask="rutMask"
            :return-masked-value="false"
            :rules="[rules.required, rules.rut, rules.isValid]"
          ></v-text-field>
        </v-flex>

        <!-- CAMPO NOMBRE -->
        <v-flex xs6 class="ml-4">
          <LabelComponent text="Nombre*" />
          <v-text-field
            name="nombre_aseg"
            class="pt-0"
            disabled
            v-model="form.nombre"
            :rules="[rules.required]"
            placeholder="Nombre"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <!-- CAMPO PRIMER APELLIDO -->
      <v-layout class="mt-1">
        <v-flex xs6 class="mr-4">
          <LabelComponent text="Primer apellido*" />
          <v-text-field
            name="fst_apellido_aseg"
            class="pt-0"
            disabled
            v-model="form.primer_apellido"
            :rules="[rules.required]"
            placeholder="Primer apellido"
          ></v-text-field>
        </v-flex>

        <!-- CAMPO SEGUNDO APELLIDO -->
        <v-flex xs6 class="ml-4">
          <LabelComponent text="Segundo apellido*" />
          <v-text-field
            name="snd_apellido_aseg"
            v-model="form.segundo_apellido"
            :rules="[rules.required]"
            class="pt-0"
            disabled
            placeholder="Segundo apellido"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <!-- CAMPO FECHA NAC -->
      <v-layout justify-center class="mt-1">
        <v-flex xs6 class="mr-4">
          <!-- CAMPO DATE CON CALENDARIO -->
          <!-- <DatePicker
          label="Fecha de nacimiento "
          :date="form.fecha_nacimiento"
          @input="form.fecha_nacimiento = $event"
          :disabled="true"
          color="#0077c8"
          :hide="false"
          :min="false"
          :max="false"
        /> -->
          <!-- CAMPO DATE SIN CALENDARIO -->
          <LabelComponent text="Fecha de nacimiento*" />
          <v-text-field
            name="fecha_nac_aseg"
            class="pt-0"
            v-model="form.fecha_nacimiento"
            placeholder="Fecha de nacimiento"
            :rules="[rules.required, rules.date, rules.date18]"
            disabled
            v-mask="dateMask"
          ></v-text-field>
          <!-- <inputText
            placeholder="dd/mm/yyyy"
            :model="form.fecha_nacimiento"
            color="#0077C8"
            size="large"
            readonly="true"
            :rules="[rules.required]"
            :hide="false"
            return-masked-value
            v-mask="dateMask"
            name="fecha_nac_aseg"
          /> -->
        </v-flex>

        <!-- CAMPO GENERO -->
        <v-flex xs6 class="ml-4">
          <LabelComponent text="Género*" />
          <v-select
            :items="genre"
            v-model="form.genero"
            disabled
            class="pt-0"
            name="genero_aseg"
            :rules="[rules.required]"
          ></v-select>
        </v-flex>
      </v-layout>

      <!-- CAMPO CORREO -->
      <v-layout justify-center class="mt-1">
        <v-flex xs6 class="mr-4">
          <LabelComponent text="Correo*" />
          <v-text-field
            name="correo_aseg"
            disabled
            v-model="form.correo"
            class="pt-0"
            placeholder="Correo"
            :rules="[rules.required, rules.email]"
          ></v-text-field>
        </v-flex>

        <!-- CAMPO TELEFONO -->
        <v-flex xs6 class="ml-4">
          <LabelComponent text="Teléfono*" />
          <v-text-field
            name="telefono_aseg"
            v-model="form.telefono"
            placeholder="Teléfono"
            :rules="[rules.required, rules.telefono]"
            disabled
            class="pt-0"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout justify-center>
        <v-flex xs6 class="mr-4">
          <LabelComponent text="Comuna*" />
          <v-autocomplete
            :items="cities"
            item-text="label"
            disabled
            v-model="form.comuna"
            :rules="[rules.required]"
            name="comuna_aseg"
            class="pt-0"
          ></v-autocomplete>
        </v-flex>

        <v-flex xs6 class="ml-4">
          <LabelComponent text="Dirección*" />
          <inputText
            placeholder="Dirección"
            :model="form.direccion"
            color="#0077C8"
            size="large"
            readonly="false"
            @input="form.direccion = $event"
            :rules="[rules.required]"
            :hide="false"
            name="direccion_aseg"
          />
        </v-flex>
      </v-layout>
      <v-layout justify-center>
        <v-flex xs6 class="mr-4">
          <LabelComponent text="Número*" />
          <inputText
            placeholder="Número"
            :model="form.numero"
            color="#0077C8"
            size="large"
            readonly="false"
            @input="form.numero = $event"
            :rules="[rules.required]"
            :hide="false"
            name="numero_aseg"
          />
        </v-flex>
        <v-flex xs6 class="ml-4">
          <LabelComponent text="Departamento/Casa" />
          <inputText
            placeholder="Departamento/Casa"
            :model="form.piso"
            color="#0077C8"
            size="large"
            readonly="false"
            @input="form.piso = $event"
            :hide="false"
            name="dpto_aseg"
          />
        </v-flex>
      </v-layout>

      <v-layout justify-start v-if="interesAsegurable">
        <v-flex xs6>
          <LabelComponent :text="select_interes.label" />
          <v-select
            v-model="form.interes"
            :items="getInsurableInterestList"
            :loading="loading_interest"
            :disabled="loading_interest"
            outlined
            dense
            item-value="codigo"
            item-text="interes_descripcion"
            class="mr-4"
            append-icon="mdi-chevron-down"
            item-disabled="eliminado"
            :rules="[rules.required]"
            single-line
            name="interes_aseg"
          ></v-select>
        </v-flex>
        <!-- CAMPO DE INFORMACION EXTRA -->
        <!-- Descomentar si se requiere nuevamente -->
        <!-- @change="(val) => handleChange(val)" -->
        <!-- <v-flex
        xs6
        :class="[
          tarifa.interes_asegurable ? 'ml-4' : 'order-first mr-4',
          'align-self-center',
        ]"
      >
        <inputText
          v-if="!showOther"
          color="#0077c8"
          :model="insured.otro"
          readonly="false"
          label="Otro"
          @input="insured.otro = $event"
        />
        <v-btn color="primary" small text @click="displayOther" v-else>
          <v-icon left>bi bi-plus-circle</v-icon> Añadir otro
        </v-btn>
      </v-flex> -->
      </v-layout>
    </v-form>
    <!-- <ModalInteres v-if="interesModal" :toggle="toggleModal" /> -->
  </div>
</template>
<script>
import inputText from "@/components/Inputs/Input";
// import DatePicker from "@/components/Inputs/DatePicker";
// import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { dateMask, rutMask } from "@/helpers/mask.js";
import { genreList } from "@/store/resources/constants.js";
import { dateToNumber } from "@/store/resources/calc.js";
import LabelComponent from "@/components/Inputs/Label.vue";
/* import ModalInteres from "@/components/Modal/Modal-interes"; */

import {
  validarFecha,
  validarEmail,
  validarRutFormat,
  validarNumerico,
  validarFecha18,
  validarRut,
} from "@/store/resources/validate.js";
export default {
  name: "InsuredForm",
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    email: {
      type: String,
      default: undefined,
    },
    phone: {
      type: [String, Number],
      default: undefined,
    },
    interesAsegurable: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    inputText,
    // DatePicker,
    LabelComponent,
    //ModalInteres,
  },

  data() {
    return {
      loading: false,
      validForm: false,
      interesModal: false,
      form: {
        tipo_documento: "",
        cotizacion: "",
        numero_documento: "",
        nombre: "",
        primer_apellido: "",
        segundo_apellido: "",
        fecha_nacimiento: "",
        genero: "",
        comuna: "",
        direccion: "",
        numero: "",
        piso: "",
        correo: "",
        telefono: "",
        interes: "",
        otro: "",
      },
      select_interes: {
        label: "Interés asegurable",
        placeholder: "Seleccione uno...",
      },
      loading_interest: false,
      // showOther: false,
      rules: {
        required: (value) => !!value || "Campo requerido.",
        rut: (value) => validarRutFormat(value) || "RUT invalido",
        date: (value) => validarFecha(value) || "Formato de fecha incorrecto",
        email: (value) => validarEmail(value) || "Correo invalido.",
        numero: (value) => validarNumerico(value) || "Número inválido",
        telefono: (value) => value.length == 9 || "Teléfono inválido",
        // interes: (value) => value === "D" || "Por favor revise la información",
        date18: (value) => validarFecha18(value) || "Debe ser mayor de edad",
        isValid: (value) =>
          validarRut(value) || "No se ha logrado reconocer el RUT",
      },
    };
  },

  created() {
    this.setup();
  },

  methods: {
    ...mapActions("Proposal", ["REQUEST_INSURABLE_INTEREST"]),
    // toggleModal() {
    //   this.interesModal = !this.interesModal;
    // },
    // handleChange(val) {
    //   if (val.codigo !== "D") {
    //     this.interesModal = true;
    //   }
    // },

    setup() {
      if (this.tarifa && this.tarifa.interes_asegurable) {
        this.setupInterest();
      }

      // ====== RELLENAR DATOS DEL ASEGURADO ======= //
      // Campos deshabilitados
      this.form.tipo_documento = this.formData?.tipo_documento ?? "";
      this.form.numero_documento = this.formData?.numero_documento ?? "";
      this.form.nombre =
        this.formData?.nombre || (this.formData?.nombres ?? "");
      this.form.primer_apellido = this.formData?.primer_apellido ?? "";
      this.form.segundo_apellido = this.formData?.segundo_apellido ?? "";
      this.form.fecha_nacimiento = dateToNumber(
        this.formData?.fecha_nacimiento ?? ""
      );
      this.form.correo =
        this.formData?.correo ||
        this.formData?.correo_cliente ||
        (this.email ?? "");
      this.form.telefono =
        this.formData?.telefono ||
        this.formData?.telefono_cliente ||
        (this.phone ?? "");
      this.form.genero = this.formData?.genero ?? "";
      // Campos rellenables
      this.form.comuna = this.formData?.comuna ?? "";
      this.form.direccion = this.formData?.direccion ?? "";
      this.form.numero = this.formData?.numero ?? "";
      this.form.piso = this.formData?.piso ?? "";
      this.form.otro = this.formData?.otro ?? "";
    },

    setupInterest() {
      this.loading_interest = true;
      this.REQUEST_INSURABLE_INTEREST()
        .then(() => {
          this.form.interes =
            this.getInsurableInterestList.find(
              (e) => e.codigo == this.formData?.interes
            )?.codigo ?? "D";
        })
        .finally(() => (this.loading_interest = false));
    },
    // displayOther() {
    //   this.showOther = !this.showOther;
    // },
  },
  computed: {
    ...mapGetters("Proposal", [
      "getCities",
      "getInsurableInterestList",
      "tarifa",
    ]),

    dateMask: () => dateMask,
    rutMask() {
      return rutMask;
    },

    genre: () => genreList,

    cities() {
      return this.getCities.answers;
    },
  },
};
</script>
<style>
.v-messages__message {
  color: #ff5252 !important;
}
</style>
