<template>
  <v-card
    class="light"
    elevation="2"
    style="cursor: pointer"
    :loading="loading"
  >
    <div class="d-flex align-center h-100 py-10 px-4">
      <v-icon large class="m-icon" color="primary">mdi-account-outline</v-icon>
      <div class="d-flex flex-column ml-3">
        <span class="h4 primary--text bold text-capitalize">{{
          name ?? ""
        }}</span>
        <span class="h4 primary--text">
          {{ docType ?? "" }}
          <span v-if="RutFormat">{{ (docNumber ?? "") | rutFormat }}</span>
          <span v-else> {{ docNumber ?? "" }}</span>
        </span>
      </div>
    </div>

    <template slot="progress">
      <v-progress-linear
        color="primary"
        height="5"
        indeterminate
      ></v-progress-linear>
    </template>
  </v-card>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    docNumber: {
      type: String,
      default: "",
    },
    docType: {
      type: String,
      default: "",
    },
    RutFormat: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
