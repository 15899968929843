<template>
  <div class="text-center">
    <v-dialog
      persistent
      v-model="open"
      width="880px"
      height="327px"
      attach="#main-container"
    >
      <v-card class="global-modalCard3">
        <v-layout fill-height>
          <v-flex class="text-left pl-10 pt-12">
            <h1 class="global-titleStyle16">¿Renunciar a la póliza vigente?</h1>
            <v-form ref="form">
              <SelectField
                color="#0077c8"
                class="global-field mt-16 ml-8"
                :fields="select_motivo"
              />
            </v-form>
          </v-flex>

          <v-flex xs4 class="d-flex align-center justify-center">
            <v-flex id="ComputerSvg">
              <div class="mr-1">
                <v-img
                  class="mt-4 mb-16"
                  max-width="200px"
                  src="@/assets/modalImages/folder.png"
                />
              </div>
            </v-flex>

            <v-img
              max-width="450px"
              width="430"
              id="BackgroundSvgs"
              src="@/assets/modalImages/Vector.png"
            />
            <div class="d-flex global-button-poliza">
              <SButton
                color="#DDECF7"
                label="No renunciar"
                v-on:click.native="handleBtnClick((item = 'close'))"
                class="mt-8 mr-3"
                width="140px"
                height="45px"
              />

              <PButton
                :loading="loadingStatus"
                color="#0077c8"
                label="Renunciar"
                v-on:click.native="
                  handleBtnClick((item = select_motivo.selected))
                "
                class="mt-8 mr-3"
                width="140px"
                height="45px"
              />
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import SelectField from "@/components/Inputs/SelectLabel";
import axios from "axios";
export default {
  data: () => ({
    dialog: true,
    loadingStatus: null,
    select_motivo: {
      answers: [],
      selected: "",
      label: "Motivo",
      rules: [(value) => !!value || "Motivo es un campo requerido."],
    },
  }),
  props: {
    open: {
      type: Boolean,
    },
  },
  components: {
    SelectField,
  },
  methods: {
    handleBtnClick(item) {
      if (item === "close") {
        this.$emit("handleBtnClick", item);
      } else {
        if (this.$refs.form.validate()) {
          this.loadingStatus = true;

          this.$emit("handleBtnClick", item);
        }
      }
    },
  },
  mounted() {
    // GET THE REASONS
    axios({
      url: "propuestas/motivos-cancelacion",
      method: "GET",
    })
      .then((resp) => {
        const motivo = resp.data.data.motivos;

        for (let i = 0; i < motivo.length; i++) {
          this.select_motivo.answers.push({
            label: motivo[i].motivo_descripcion,
            value: motivo[i].motivo,
          });

          // formatted[i] = { value: r.data[i].id, text: r.data[i].name }
        }
      })
      .catch(() => {});
  },
  watch: {
    open() {
      if (this.open == false) {
        this.loadingStatus = null;
      }
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
#BackgroundSvgs {
  position: absolute;
  top: 0%;
  max-width: 450px;
  margin-right: 110px;
  z-index: 1;
}
#ComputerSvg {
  z-index: 10;
}

:deep() .v-dialog {
  border-radius: 4px;
  margin: 24px;
  overflow: hidden;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
}
</style>
