<template>
  <v-card
    class="light h-100"
    :class="[disabled ? 'card-disabled' : 'global-cardStyle2 card-selectable']"
    :loading="loading"
    :elevation="disabled ? 0 : 5"
  >
    <div class="d-flex pa-3 flex-column">
      <div class="d-flex align-center">
        <v-icon x-large>{{ getPayment(cardData.codigo)?.icon }}</v-icon>
        <p class="mb-0 px-3 subtitle-1 semiBold primary--text">
          <span v-if="cardData.codigo == methodsCodes[1]">
            {{
              cardData.numero_tarjeta
                ? cardData.numero_tarjeta[0] == 4
                  ? "Visa Débito"
                  : "MasterCard"
                : ""
            }}
          </span>

          <span v-else>
            {{
              cardData?.medio_pago_descripcion ||
              getPayment(cardData.codigo)?.title
            }}
          </span>
        </p>
      </div>

      <v-flex
        v-if="
          cardData.codigo === methodsCodes[5] &&
          disabled &&
          cardData.codigo_estado == statusCodes[1]
        "
        class="text-left"
      >
        <!-- BOTON RECARGAR ESTADO DE MPAY -->
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              small
              fab
              text
              @click="$emit('mpay-refresh', cardData)"
              :loading="mpayLoading"
              v-on="on"
              v-bind="attrs"
              name="boton_refrescar"
            >
              <v-icon>mdi-refresh-circle</v-icon>
            </v-btn>
          </template>
          <span>Consultar nuevamente el estado de la validación</span>
        </v-tooltip>

        <!-- BOTON RECHAZAR MPAY -->
        <v-tooltip top color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              small
              fab
              text
              @click="$emit('mpay-cancel', cardData)"
              :loading="loadingCancel"
              v-on="on"
              v-bind="attrs"
              name="boton_anular"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </template>
          <span>Anular la solicitud</span>
        </v-tooltip>
      </v-flex>

      <v-flex xs12>
        <p
          class="caption mb-0"
          v-if="
            cardData.codigo === methodsCodes[4] ||
            cardData.codigo === methodsCodes[2] ||
            cardData.codigo == methodsCodes[1]
          "
        >
          <span class="p semiBold">
            <span v-if="cardData.codigo != methodsCodes[1]">Cuenta</span>
            <span v-else></span> Terminada en
            {{
              (cardData.codigo != methodsCodes[1]
                ? cardData.informacion.numero_cuenta_corriente
                : cardData.numero_tarjeta
              ).substring(
                (cardData.codigo != methodsCodes[1]
                  ? cardData.informacion.numero_cuenta_corriente
                  : cardData.numero_tarjeta
                ).length - 4
              )
            }}
          </span>
          <br />
          <span>Factura los dias {{ cardData.informacion.dia }}</span>
        </p>
        <p v-else class="semiBold mt-1">
          {{ getPayment(cardData.codigo)?.method }}
        </p>
      </v-flex>
      <span class="mt-2">{{
        cardData?.informacion?.banco_descripcion ?? ""
      }}</span>
    </div>
    <v-layout class="pa-3" wrap>
      <v-flex xs12>
        <h3
          class="global-thirdTitleStyle"
          v-if="
            cardData.codigo === methodsCodes[2] ||
            cardData.codigo === methodsCodes[4]
          "
        >
          {{ cardData.banco_descripcion }}
        </h3>
      </v-flex>
      <v-flex
        xs12
        md12
        v-if="
          cardData.codigo == methodsCodes[5] ||
          cardData.codigo == methodsCodes[6] ||
          cardData.codigo == methodsCodes[7]
        "
      >
        <p class="caption mb-0 mt-2 text-right">Validación del pago:</p>
        <p class="text-right">
          <template v-if="!mpayLoading">
            <v-icon
              left
              :color="
                getPaymentStatus(cardData.codigo_estado || cardData.estado)
                  ?.color
              "
              >mdi-circle</v-icon
            >
            {{
              getPaymentStatus(cardData.codigo_estado || cardData.estado)?.text
            }}
          </template>
          <template v-else>
            <v-progress-circular
              indeterminate
              color="grey"
            ></v-progress-circular>
          </template>
        </p>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
import {
  paymentMethods,
  paymentStatus,
  paymentStatusCodes,
  paymentMethodsCodes,
} from "@/store/resources/payments.js";
export default {
  name: "PaymentCardComponent",
  props: {
    cardData: {
      type: Object,
      default: () => ({}),
    },
    cardHeight: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    mpayLoading: {
      type: Boolean,
      default: false,
    },
    loadingCancel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    payments() {
      return paymentMethods;
    },
    statusCodes: () => paymentStatusCodes,
    methodsCodes: () => paymentMethodsCodes,
  },
  methods: {
    getPayment(code) {
      return this.payments.find((e) => e.code === code);
    },
    getPaymentStatus(status) {
      return paymentStatus.find(
        (e) => e.code === status || e.code === "DEFAULT"
      );
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
.card-selectable {
  cursor: pointer;
}
.card-disabled {
  border: solid 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.025);
}
</style>
