export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    next: {
      type: Function,
      default: () => null,
      description: "Metodo de ir al siguiente paso",
    },
    back: {
      type: Function,
      default: () => null,
      description: "Metodo de regresar al paso anterior",
    },
    data: {
      type: Object,
      default: () => {},
      description: "Datos guardados del formulario",
    },
    proposal: {
      type: [Number, String],
      default: "",
      description: "Numero de propuesta",
    },
    channel: {
      type: String,
      default: "",
      description: "Canal actual",
    },
    client: {
      type: Object,
      default: () => null,
      description: "Datos guardados del cliente",
    },
  },
};
