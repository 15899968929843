<template>
  <v-dialog persistent v-model="open" width="480px" attach="#main-container">
    <v-card class="br-12">
      <v-card-text>
        <v-row class="pt-5">
          <v-col cols="12" class="d-flex justify-space-between">
            <div>
              <h4 class="mb-2">¡Ya falta poco!</h4>
              <span class="">Nº de propuesta: {{ propuesta }}</span>
            </div>
            <v-btn @click="ActionButton" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="7">
            <v-card class="light" elevation="0">
              <v-card-text>
                <ul>
                  <li>
                    <span class="p">
                      Esta propuesta
                      <span class="semiBold">ya no es modificable</span>
                    </span>
                  </li>
                  <li class="mt-3">
                    Se debe <span class="semiBold">firmar</span> para finalizar
                    el proceso
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="5">
            <v-img src="@/assets/svg/modalPropuesta.svg"></v-img>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <Alert variant="info">
              <template slot="content">
                <div v-html="alert_message"></div>
              </template>
            </Alert>
            <div class="text-center">
              <v-btn
                color="primary"
                width="214"
                rounded
                height="41"
                @click="ActionButton"
                class="mt-8 text-none"
                name="boton_continuar"
                >Continuar</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Alert from "@/components/Alert/Alert.vue";

export default {
  data: () => ({
    dialog: true,
  }),
  props: {
    open: {
      type: Boolean,
    },
    rut: {
      type: String,
    },
    name: {
      type: String,
    },
    email: {
      type: [String, Number],
    },
    SuccessPath: {
      type: String,
      require: true,
    },
    propuesta: {
      type: Number,
    },
    alert_message: {
      type: String,
    },
  },
  methods: {
    ActionButton() {
      this.$emit("ActionButton");
    },
  },
  computed: {
    ...mapGetters("Users", ["getTituloColor"]),
    titleColor() {
      if (this.getTituloColor != null) {
        return this.getTituloColor;
      } else {
        return "#0077c8";
      }
    },
  },
  components: {
    Alert,
  },
};
</script>
