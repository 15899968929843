<template>
  <div>
    <ModalEditEmail
      v-model="editEmailModalOpen"
      @close="editEmailModalOpen = false"
      :clientEmail="correoAsegurado"
      :ProposalNumber="UserInfo?.numero_propuesta"
      @setDocs="setDocs"
    />
    <v-container v-if="stepValidate === 0">
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <h6>
            Para finalizar falta la firma digital del cliente para los
            siguientes archivos:
          </h6>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <SimpleTable
            :headers="TableHeaders"
            :items="items"
            :DownloadButton="downDownloadbtn"
            :ShowButton="showbtn"
            :loading="loading.docs"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-alert type="info" text border="left" dense>
            <v-row align="center">
              <v-col class="grow">
                Para completar el proceso de venta se requiere la firma del
                cliente. Para eso se enviará un Token al email
                <b
                  ><v-icon color="primary">mdi-email</v-icon>
                  {{ correoAsegurado || "..." }}</b
                >
              </v-col>
              <v-col class="shrink">
                <v-btn
                  text
                  class="text-none"
                  color="primary"
                  @click="toggleModalOpen"
                >
                  <v-icon left>mdi-pencil</v-icon>
                  Editar email
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="text-center"
          v-for="(item, i) in firmas_to_show"
          :key="i"
        >
          <v-btn
            @click="handlerClickSignature(item.codigo)"
            color="primary"
            rounded
            class="text-none"
            width="180"
            :outlined="item.codigo !== 'DIG'"
            :disabled="disabledBtn"
            :loading="loading[item.codigo]"
            :name="`boton_firma_${item.codigo}`"
            >{{ item.descripcion }}</v-btn
          >
        </v-col>
      </v-row>

      <v-layout wrap class="d-flex" justify-center>
        <ModalFile v-if="openModal" />
        <ModalFirmaManual
          v-if="openModalFirmaManual"
          @cerrarModal="cerrarModalFirma()"
          :numero_propuesta="UserInfo.numero_propuesta"
        />
        <ModalFirmaManualDes
          v-if="openModalFirmaManualDes"
          @cerrarModal="cerrarModalFirmaManualDes()"
        />
      </v-layout>
    </v-container>
    <ValidarToken
      v-else
      @firma="FirmaDigital(true)"
      :loadingToken="loading.token"
      :session_id="session_id"
      :propuesta="this.UserInfo.numero_propuesta"
      :toggle="toggleModalOpen"
      :UserInfo="UserInfo"
      @back="back"
      :correoFirmaDig="correoFirmaDig"
    />
  </div>
</template>
<script>
import axios from "axios";
import ValidarToken from "./ValidarToken.vue";
import { mapGetters, mapActions } from "vuex";
import ModalFile from "@/components/Modal/Modal-show-file.vue";
import ModalEditEmail from "@/components/Modal/Modal-edit-email.vue";
import ModalFirmaManual from "@/components/Modal/Modal-firma-manual.vue";
import ModalFirmaManualDes from "@/components/Modal/Modal-firma-manual-des.vue";

export default {
  name: "FirmaContractPage",
  props: {
    UserInfo: {
      type: [Array, Object],
    },
    signatureType: {
      type: Array,
      default: () => [],
      description: "Lista de tipos de firma para el canal",
    },
    channel: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ValidarToken,
    ModalFile,
    ModalEditEmail,
    ModalFirmaManual,
    ModalFirmaManualDes,
  },
  data() {
    return {
      loading: {
        DIG: false,
        MAN: false,
        docs: false,
        token: false,
      },
      editEmailModalOpen: false,
      disabledBtn: false,
      stepValidate: 0,
      session_id: "",
      TableHeaders: [
        {
          text: "Nombre",
          value: "archivo_descripcion",
          width: "20%",
          align: "center",
        },
        {
          text: "Tipo",
          value: "documento_descripcion",
          width: "20%",
          align: "center",
        },
        /* {
          text: "Tamaño",
          value: "size_archivo",
          width: "20%",
          align: "center",
        }, */
        {
          text: "Fecha",
          value: "fecha_creacion",
          width: "20%",
          align: "center",
        },
        {
          text: "Acción",
          value: "ci_action",
          width: "10%",
          align: "center",
          sortable: false,
        },
      ],
      items: [],
      openModalFirmaManual: false,
      openModalFirmaManualDes: false,
      firmaManual: false,
      firmaDigital: false,
      firmas_to_show: [],
      tipos_firma: [
        { codigo: "DIG", descripcion: "Firma digital" },
        { codigo: "MAN", descripcion: "Firma manual" },
      ],
      correoAsegurado: "",
      btnFirma: false,
      correoFirmaDig: "",
    };
  },
  methods: {
    ...mapActions("Payment", ["OpenShowModal"]),
    ...mapActions("Proposal", ["setDigitalSignature"]),
    toggleModalOpen() {
      this.editEmailModalOpen = !this.editEmailModalOpen;
    },
    DownloadFiles() {
      if (this.items.length > 0) {
        this.items.map((it) => {
          const blob = this.b64toBlob(it.data_archivo, it.tipo_archivo);

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          var nameDownload = it.archivo_descripcion.split(".")[0];
          link.download = nameDownload + ".pdf";

          link.click();
        });
      }

      this.FirmaManual();
    },
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    downDownloadbtn(item) {
      if (item?.data_archivo) {
        const blob = this.b64toBlob(item.data_archivo, item.tipo_archivo);

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        var nameDownload = item.archivo_descripcion.split(".")[0];
        link.download = nameDownload + ".pdf";
        link.click();
      } else {
        this.$store.commit("setSnackbar", {
          active: true,
          text: "Datos de descarga del archivo no encontrados",
          top: true,
          right: true,
          color: "warning",
        });
      }
    },

    showbtn(item) {
      this.OpenShowModal({ item });
    },

    back() {
      this.stepValidate = 0;
    },

    handlerClickSignature(code = "") {
      switch (code) {
        case "DIG":
          this.FirmaDigital(false);
          break;
        case "MAN":
          this.FirmaManual();
          /* this.DownloadFiles(); */
          break;
        default:
          break;
      }
    },

    FirmaDigital(v) {
      this.loading.DIG = true;
      this.loading.token = true;
      this.setDigitalSignature(this.UserInfo.numero_propuesta)
        .then(({ result, info }) => {
          this.session_id = result?.Propuesta.firma.session_id;
          this.correoFirmaDig = result?.Propuesta?.asegurado?.correo;

          this.$emit("successToken");
          this.stepValidate = 1;
          if (v) {
            this.$store.commit("setSnackbar", {
              active: true,
              text: info,
              top: true,
              right: true,
              color: "success",
            });
          }
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error?.data?.lista_mensaje?.[0]?.mensaje ?? error.info,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loading.DIG = false;
          this.loading.token = false;
        });
    },
    FirmaManual() {
      this.loading.MAN = true;
      axios({
        /* ${process.env.VUE_APP_BASE_URL_NEGOCIOS} */
        url: `/canales/metodos-firma-canal/${this.channel.id}`,
        method: "GET",
      })
        .then((response) => {
          const result = response.data.data.tipos_firma;
          if (result.MAN) {
            this.openModalFirmaManual = true;
          } else {
            this.openModalFirmaManualDes = true;
          }
        })
        .finally(() => (this.loading.MAN = false));
    },
    setDocs(docsArray = [], correo, modal, btnsFirma) {
      this.disabledBtn = true;
      this.loading.docs = true;
      this.correoAsegurado = correo;
      this.items = docsArray;
      this.editEmailModalOpen = modal;
      this.btnFirma = btnsFirma;
      this.GetDocs();
    },
    GetDocs() {
      axios({
        url: `propuestas/${this.UserInfo.numero_propuesta}/firma/documentos`,
        method: "GET",
      })
        .then((res) => {
          this.items = res?.data?.data?.documentos;
          this.correoAsegurado = res?.data?.data?.asegurado?.correo;
          this.firmaDigital = res?.data?.data?.tipos_firma?.DIG;
          this.firmaManual = res?.data?.data?.tipos_firma?.MAN;
          if (!this.btnFirma) {
            this.firmas();
          }
        })
        .catch()
        .finally(() => {
          this.loading.docs = false;
          this.disabledBtn = false;
        });
    },
    cerrarModalFirma() {
      this.openModalFirmaManual = false;
    },
    cerrarModalFirmaManualDes() {
      this.openModalFirmaManualDes = false;
    },
    firmas() {
      var firmaDig = {};
      var firmaMan = {};
      switch (this.signatureType.length < 2) {
        case this.signatureType.length === 1: {
          if (this.firmaDigital) {
            this.signatureType.find((el) => el.codigo === "DIG");
            firmaDig = this.tipos_firma.find((el) => el.codigo === "DIG");
            this.firmas_to_show.push(firmaDig);
          }
          if (this.firmaManual) {
            this.signatureType.find((el) => el.codigo === "MAN");
            firmaMan = this.tipos_firma.find((el) => el.codigo === "MAN");
            this.firmas_to_show.push(firmaMan);
          }
          break;
        }
      }
      return this.firmas_to_show;
    },
  },
  mounted() {
    this.disabledBtn = true;
    this.loading.docs = true;
    this.GetDocs();
  },
  computed: {
    ...mapGetters("Payment", ["getShowModal"]),
    ...mapGetters("Proposal", ["getProposalData"]),

    openModal() {
      return this.getShowModal;
    },
    isPosted() {
      return this.$store.getters.isPosted;
    },
  },
};
</script>
