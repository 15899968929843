<template>
  <div class="text-center">
    <v-dialog
      v-model="open"
      width="60%"
      persistent
      name="dialog_datos_faltantes"
    >
      <v-card class="global-modalCard5">
        <v-layout wrap class="pa-0">
          <v-flex xs10 class="text-left ml-8 pt-10" style="z-index: 10">
            <h1 class="global-text5">Firma Manual</h1>
            <!-- <v-spacer></v-spacer> -->
            <p class="mt-5 global-subtitle-modal">
              La firma manual ha sido <br />deshabilitada
            </p>
            <v-layout class="mt-8">
              <div
                id="ComputerSvg"
                class="ml-0"
                style="align-items: center; justify-content: center"
              >
                <v-img
                  class="image-cuate"
                  width="250px"
                  src="@/assets/modalImages/warning.png"
                  contain
                />
              </div>
            </v-layout>
          </v-flex>
          <v-img
            width="400px"
            id="BackgroundSvgs"
            src="@/assets/modalImages/Vector.png"
          />

          <v-flex class="d-flex align-center justify-center ml-5 mt-14">
            <v-btn
              color="primary"
              class="mr-6 pr-6 pl-6 mt-5"
              width="214"
              height="41"
              outlined
              @click="$emit('cerrarModal')"
              >Cerrar</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    numero_propuesta: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {},
  computed: {},
  components: {},
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

h4 {
  z-index: 10;
}

.image-cuate {
  position: absolute;
  top: 8%;
  left: 60%;
  max-width: 500px;
  z-index: 0;
}

#BackgroundSvgs {
  position: absolute;
  top: 0%;
  right: 0%;
  max-width: 500px;
}

#ComputerSvg {
  z-index: 10;
}

:deep() .v-dialog {
  border-radius: 4px;
  margin: 24px;
  overflow: hidden;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
}
</style>
