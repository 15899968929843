<template>
  <v-form ref="docForm">
    <div v-if="newCar">
      <!-- Formulario de auto nuevo (Si el canal requiere documento) -->
      <v-row v-if="needDocument">
        <v-col cols="12">
          <!-- Fecha de factura -->
          <LabelComponent text="Fecha de factura*" />
          <v-menu
            ref="menu1"
            v-model="dateMenu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            name="RequirementsDate"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                :rules="[(v) => greenSale || !!v || 'Fecha factura requerido']"
                name="fecha_factura"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="documentos.factura.fecha"
              @input="handlerCheckDate"
              :active-picker.sync="activePicker"
              min="1950-01-01"
              @change="save"
              name="picker_fecha_factura"
              locale="es-ch"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <!-- Numero de factura -->
          <LabelComponent text="Número de factura*" />
          <v-text-field
            outlined
            dense
            type="number"
            :rules="[(v) => greenSale || !!v || 'Numero de factura requerido']"
            v-model="documentos.factura.numero"
            name="numero_factura"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <!-- Docum ento Adjunto -->
          <LabelComponent text="Adjuntar factura" />
          <v-file-input
            outlined
            dense
            prepend-icon=""
            @change="handlerSelectFile"
            accept="application/pdf"
            name="adj_doc_factura"
          >
            <template slot="append">
              <v-icon name="upload">mdi-upload</v-icon>
            </template>
          </v-file-input>
        </v-col>
      </v-row>
      <!-- Formulario de auto nuevo (el canal NO requiere documento) -->
      <p v-else class="caption">
        <v-icon left class="m-icon">mdi-circle-small</v-icon> Carga de documento
        o factura no disponible.
      </p>
    </div>

    <!-- VEHICULO USADO
    <p v-if="!needInspection" class="caption">
      <v-icon left class="m-icon">mdi-circle-small</v-icon>
      Inspección no disponible
    </p>
    <p v-if="!needDocument" class="caption">
      <v-icon left class="m-icon">mdi-circle-small</v-icon> Carga de documento o
      factura no disponible.
    </p>
  </div> -->

    <!-- INFORMACION ADICIONAL -->
    <v-slide-y-transition>
      <v-row v-if="oldDate || (!newCar && needDocument)">
        <v-col cols="12">
          <!-- Tipo de Documento -->
          <LabelComponent text="Tipo de Documento*" />
          <v-select
            :items="getDocumentType"
            v-model="guia_despacho_nota"
            :loading="loadingDocType"
            outlined
            dense
            item-text="descripcion"
            return-object
            append-icon="mdi-chevron-down"
            :rules="[(v) => !!v || 'Debe seleccionar un tipo de documento.']"
            name="doc_adicional"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <!-- Fecha de documento -->
          <LabelComponent text="Fecha de documento*" />
          <v-menu
            ref="menu2"
            v-model="dateMenu2"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormattedAd"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                name="fecha_doc_adicional"
                :rules="[
                  (v) => !!v || 'Debe seleccionar una fecha de documento.',
                  (v) =>
                    handlerCheckDateAditional(v) ||
                    'La fecha del documento adicional no puede tener mas de 48h de antigüedad.',
                ]"
              ></v-text-field>
              <!-- :rules="greenSale != 'N' ? [] : [rules.hrs48]" -->
            </template>
            <v-date-picker
              v-model="documentos.adicional.fecha"
              :active-picker.sync="activePicker2"
              min="1950-01-01"
              @change="save2"
              name="picker_fecha_adicional"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <!-- Numero de documento -->
          <LabelComponent text="Número de documento*" />
          <v-text-field
            outlined
            dense
            v-model="documentos.adicional.numero"
            name="numero_doc_adicional"
            :rules="[
              (v) => !!v || 'El campo numero de documento es requerido.',
            ]"
          ></v-text-field>
          <!-- :rules="greenSale != 'N' ? [] : [rules.required]" -->
        </v-col>
        <v-col cols="12">
          <!-- Documento adjunto -->
          <LabelComponent text="Adjuntar documento" />
          <v-file-input
            outlined
            dense
            prepend-icon=""
            @change="handlerSelectFile($event, 'adicional')"
            accept="application/pdf"
            name="adj_doc_adicional"
          >
            <template slot="append">
              <v-icon>mdi-upload</v-icon>
            </template>
          </v-file-input>
        </v-col>
      </v-row>
    </v-slide-y-transition>

    <div class="d-flex flex-column">
      <div class="d-flex align-center">
        <v-icon name="warning" color="error">mdi-alert</v-icon>
        <span class="error-text ml-1 semiBold">ADVERTENCIA</span>
      </div>
      <span>
        A partir de este punto, ya no se podrá modificar esta propuesta.
      </span>
    </div>

    <!-- BOTONES DIRECCIONALES -->
    <div class="text-center mt-6">
      <v-btn
        color="primary"
        class="mx-1 text-none"
        outlined
        rounded
        @click="$emit('back')"
        :disabled="loading"
        width="140"
        name="boton_volver"
        >Volver</v-btn
      >
      <v-btn
        color="primary"
        class="mx-1 ml-4 text-none"
        rounded
        width="140"
        @click="handlerNext()"
        :loading="loading"
        name="boton_continuar"
        >Continuar</v-btn
      >
      <ModalFecha v-model="showDateModal" @close="showDateModal = false" />
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LabelComponent from "@/components/Inputs/Label.vue";
import ModalFecha from "@/components/Modal/Modal-fecha-factura.vue";

export default {
  name: "DocumentForm",
  props: {
    data: {
      type: Array,
      default: () => [],
      description: "Datos para el formulario",
    },
    proposalNumber: {
      type: [String, Number],
      default: "",
      description: "Numero de la propuesta",
    },
    newCar: {
      type: Boolean,
      default: false,
      description: "Indicador para definir si el automovil es nuevo",
    },
    needInspection: {
      type: Boolean,
      default: false,
      description: "Indicador para definir si la propuesta amerita inspeccion",
    },
    needDocument: {
      type: Boolean,
      default: false,
      description:
        "Indicador para definir si la propuesta amerita que el usuario adjunte un documento de factura",
    },
    channel: {
      type: String,
      default: "",
      description: "Nombre del canal",
    },
    greenSale: {
      type: Boolean,
      default: false,
    },
  },
  components: { LabelComponent, ModalFecha },
  data() {
    return {
      loading: false,
      loadingDocType: false,
      dateMenu1: false,
      dateMenu2: false,
      activePicker: null,
      activePicker2: null,
      oldDate: false,
      showDateModal: false,
      oldDateAditional: false,
      selected: "",
      documentos: {
        factura: {
          fecha: "",
          numero: "",
          documento: "",
          nombre: "",
          mime: "",
        },
        adicional: {
          fecha: "",
          numero: "",
          documento: "",
          nombre: "",
          mime: "",
        },
      },
      doc1: null,
      doc2: null,
      guia_despacho_nota: null,
      rules: {
        required: (value) => !!value || "Campo requerido.",
        hrs48: (value) =>
          (value && !this.oldDateAditional) ||
          "Fecha del archivo debe ser menor a 48 horas.",
      },
    };
  },
  created() {
    this.handlerGetDocumentType();
    this.hanlderSetForm();
  },
  computed: {
    ...mapGetters("Proposal", [
      "getDocumentType",
      "getProposalD",
      "getProposalStepData",
    ]),
    formIsValidFactura() {
      return (
        this.documentos.factura.fecha !== "" ||
        this.documentos.factura.numero !== "" ||
        this.documentos.factura.documento !== ""
      );
    },
    formIsValidAdicional() {
      return (
        this.documentos.adicional.fecha !== "" ||
        this.documentos.adicional.numero !== "" ||
        this.documentos.adicional.documento !== "" ||
        this.guia_despacho_nota
      );
    },
    documentCodes() {
      return {
        factura: {
          tipo: "FAC",
          descripcion: "FACTURA",
        },
        adicional: {
          tipo: "GDNV",
          descripcion: "Guia despacho nota venta",
        },
        venta: {
          tipo: "DOC_VENTA",
          descripcion: "Documento venta",
        },
      };
    },
    computedDateFormatted() {
      return this.formatDate(this.documentos.factura.fecha);
    },
    computedDateFormattedAd() {
      return this.formatDate(this.documentos.adicional.fecha);
    },
  },
  methods: {
    ...mapActions("Proposal", [
      "updateRequirements",
      "REQUEST_DOCUMENT_TYPE",
      "getDataProposalStep",
    ]),
    selectType(selected) {
      this.selected = selected;
    },

    handlerNext() {
      if (this.$refs.docForm.validate()) {
        this.loading = true;
        // Crear la data del formulario de envio
        let formData = {},
          factura = this.documentos.factura,
          adicional = this.documentos.adicional;

        if (this.guia_despacho_nota) {
          formData.guia_despacho_nota = this.guia_despacho_nota;
        }
        if (this.newCar) {
          // Si es auto NUEVO
          formData.auto_es_nuevo = this.newCar;
          formData.requiere_documento_venta = this.needDocument;

          formData.documentos = [];
          if (this.formIsValidFactura) {
            factura.tipo = this.documentCodes.factura.tipo;
            factura.tipo_descripcion = this.documentCodes.factura.descripcion;
            formData.documentos.push(factura);
          }
          if (this.formIsValidAdicional && this.oldDate) {
            adicional.tipo = this.documentCodes.adicional.tipo;
            adicional.tipo_descripcion =
              this.documentCodes.adicional.descripcion;
            formData.documentos.push(adicional);
          }
        } else {
          // Si es auto USADO
          formData.requiere_inspeccion = this.needInspection;
          formData.requiere_documento_venta = this.needDocument;
          formData.adjunto_documento = adicional?.documento !== "";

          adicional.tipo = this.documentCodes.adicional.tipo;
          adicional.tipo_descripcion = this.documentCodes.adicional.descripcion;
          formData.documento = adicional;
        }
        // Enviar data
        this.updateRequirements({
          numero_propuesta: this.proposalNumber,
          form: formData,
        })
          .then(() => {
            this.$emit("next");
          })
          .catch((message) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: message,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => (this.loading = false));
      }
    },
    save(date) {
      this.$refs.menu1.save(date);
    },
    save2(date) {
      this.$refs.menu2.save(date);
    },
    // Verificar fecha seleccionada (en caso de que sea menor a 2 dias respecto a la fecha actual)
    handlerCheckDate(date) {
      const currentDate = new Date(),
        selectedDate = new Date(
          `${date} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`
        );

      currentDate.setDate(currentDate.getDate() - 2);
      this.oldDate = selectedDate < currentDate;
      if (this.oldDate) {
        this.showDateModal = true;
      }
    },
    handlerCheckDateAditional(date = "") {
      let setCondition = false;
      if (date) {
        const [day, month, year] = date.split("/");
        const currentDate = new Date();
        const selectedDate = new Date(
          year,
          month - 1,
          day,
          currentDate.getHours(),
          currentDate.getMinutes(),
          currentDate.getSeconds()
        );
        currentDate.setDate(currentDate.getDate() - 2);
        this.oldDateAditional = selectedDate < currentDate;
        setCondition = selectedDate >= currentDate;
      }
      return setCondition;
    },
    async handlerSelectFile(file, tipo = "factura") {
      this.documentos[tipo].documento = await this.toBase64(file);
      this.documentos[tipo].mime = file.type;
      this.documentos[tipo].nombre = file.name;
    },
    handleDateModal() {
      this.showDateModal = false;
    },
    // Convertir archivo de File a base64
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.toString().split(",")[1]);
        reader.onerror = (error) => reject(error);
      });
    },
    resetAditionalForm() {
      this.documentos.adicional.documento = "";
      this.documentos.adicional.mime = "";
      this.documentos.adicional.nombre = "";
      this.guia_despacho_nota = null;
      this.documentos.adicional.fecha = "";
      this.documentos.adicional.numero = "";
    },
    handlerGetDocumentType() {
      this.loadingDocType = true;
      this.REQUEST_DOCUMENT_TYPE().finally(() => (this.loadingDocType = false));
    },
    hanlderSetForm() {
      this.documentos.factura.fecha = this.data?.[0]?.fecha ?? "";
      this.documentos.factura.numero = this.data?.[0]?.numero ?? "";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  watch: {
    oldDate: function (newVal, oldVal) {
      if (!newVal && oldVal) {
        this.resetAditionalForm();
      }
    },
  },
};
</script>
