<template>
  <v-row dense class="align-center justify-center">
    <v-col cols="12" md="8">
      <v-card>
        <v-card-title primary-title> Pagador </v-card-title>
        <v-card-text>
          <chile-form
            ref="formComponent"
            v-if="isChile"
            docTypeDescription="RUT"
          />
          <peru-form
            ref="formComponent"
            v-else-if="isPeru"
            docTypeDescription="DNI"
          />
        </v-card-text>

        <v-card-actions class="py-5 px-6">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mr-2 text-none"
            width="140"
            outlined
            rounded
            @click="goBack"
            :disabled="loading"
            name="boton_volver"
            >Volver</v-btn
          >
          <v-btn
            color="primary"
            class="ml-2 text-none"
            rounded
            width="140"
            @click="goNext"
            :loading="loading"
            name="boton_continuar"
            >Continuar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

import ChileForm from "./PayerFormChile.vue";
import PeruForm from "./PayerFormPeru.vue";

import CountryMixin from "@/Mixins/CountryMixin.js";
import { dateToNumber } from "@/store/resources/calc.js";
export default {
  name: "PayerForm",
  mixins: [CountryMixin],
  components: {
    ChileForm,
    PeruForm,
  },
  props: {
    proposal: {
      type: String,
      default: "",
    },
    docType: {
      type: String,
      default: "",
    },
    docTypeDescription: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    formBindData() {
      return { loading: this.loading };
    },
  },
  methods: {
    goBack() {
      this.$emit("back", true);
    },
    goNext() {
      const formComponent = this.$refs.formComponent;
      const form = formComponent?.form;
      const isValid = formComponent?.$refs.form.validate();
      if (isValid) {
        this.loading = true;
        // Eliminar mascaras
        const { numero_documento, fecha_nacimiento, ...rest } = form;
        const formData = {
          ...rest,
          numero_documento: numero_documento.replace(/[^A-Z0-9]/gi, ""),
          fecha_nacimiento: dateToNumber(fecha_nacimiento),
        };

        const dataPayer = {
          numero_propuesta: this.proposal,
          numero_documento: form.numero_documento,
          payer_data: formData,
        };

        this.createPayer(dataPayer);
      }
    },
    createPayer(data) {
      axios({
        url: `propuestas/${data.numero_propuesta}/pagador`,
        method: "POST",
        data: data.payer_data,
      })
        .then(() => {
          this.$emit("next", data.numero_documento);
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: "Ha ocurrido un error al intentar crear al pagador.",
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
