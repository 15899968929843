var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","value":_vm.open,"scrollable":"","width":"38%","attach":"#main-container"}},[_c('v-card',{staticClass:"br-6",attrs:{"min-height":"535px"}},[_c('v-card-text',{staticClass:"pa-4 pb-6"},[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.showCloseBtn)?_c('v-col',{staticClass:"d-flex w-100 justify-space-between align-center"},[_c('span',{staticClass:"h5 semiBold"}),(
              !(_vm.infoMPAY?.estado === 'PENDIENTE') ||
              !(_vm.data?.estado === 'PENDIENTE')
            )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1):_vm._e()],1):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(_vm.infoMPAY?.estado === 'GENERADA' && !_vm.loading)?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-check-circle")]),_c('Alert',{staticClass:"w-100 mt-2",attrs:{"variant":"success"}},[_c('template',{slot:"content"},[_c('div',[_c('span',{staticClass:"p"},[_vm._v("Póliza generada con éxito ")])])])],2),_c('p',[_vm._v(" REF: "),_c('b',[_vm._v(_vm._s(_vm.infoMPAY?.poliza ?? ""))])])],1):(
              _vm.infoMPAY?.estado === 'PENDIENTE' || _vm.data?.estado === 'PENDIENTE'
            )?_c('div',{staticClass:"d-flex flex-column align-center mt-2"},[_c('v-progress-circular',{staticClass:"primary--text",attrs:{"rotate":-60,"size":80,"width":10,"indeterminate":""}}),_c('span',{staticClass:"p mt-2"},[_vm._v(" Estamos generando su póliza")])],1):(_vm.infoMPAY?.estado === 'ERROR' || _vm.data?.estado === 'ERROR')?_c('div',{staticClass:"d-flex flex-column align-center mt-2"},[_c('v-icon',{attrs:{"x-large":"","color":"warning"}},[_vm._v("mdi-alert-outline")]),_c('Alert',{staticClass:"w-100 mt-2",attrs:{"variant":"warning"}},[_c('template',{slot:"content"},[_c('div',[_c('span',{staticClass:"p"},[_vm._v(" La operación ha sido registrada con éxito. Sin embargo, tuvimos un problema en la generación del documento que respalda la póliza. "),_c('br'),_c('br'),_vm._v(" Seguiremos trabajando en su emisión y le "),_c('span',{staticClass:"semiBold"},[_vm._v("enviaremos la póliza al correo electrónico del cliente")]),_vm._v(" a la brevedad. ")])])])],2)],1):_vm._e()])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{staticClass:"light w-100",attrs:{"elevation":"0"}},[_c('v-card-text',[(
                  _vm.infoMPAY?.estado === 'ERROR' || _vm.data?.estado === 'ERROR'
                )?_c('v-alert',{attrs:{"type":"error","outlined":"","dense":""}},[_vm._v(" "+_vm._s(_vm.data?.lista_mensaje[0].mensaje ? _vm.data?.lista_mensaje[0].mensaje : _vm.infoMPAY?.lista_mensaje[0]?.mensaje ?? "")+" ")]):_vm._e(),(
                  _vm.infoMPAY?.estado === 'PENDIENTE' ||
                  _vm.data?.estado === 'PENDIENTE'
                )?_c('ul',[_c('li',[_vm._v("El envío del token se encuentra en progreso.")]),_c('li',[_vm._v("Una vez verificado el token, la poliza será generada.")]),_c('li',[_vm._v(" Podrá ver la información de su gestión una vez finalizado el proceso. ")])]):(
                  _vm.infoMPAY?.estado === 'ERROR' || _vm.data?.estado === 'ERROR'
                )?_c('ul',[_c('li',[_vm._v(" Revisa en el historial de tus gestiones para confirmar que todo quedo bien. ")]),_c('li',[_vm._v(" Si no queda regularizado en los próximos minutos contacta al equipo de soporte escribiendo al: "),_c('span',{staticClass:"bold"},[_vm._v("soporte.plataforma@mesos.cl")]),_vm._v(" o llamando al "),_c('span',{staticClass:"bold"},[_vm._v("227564013.")])])]):(_vm.infoMPAY?.estado === 'GENERADA' || _vm.data?.estado === 'GENERADA')?_c('ul',[_c('li',[_vm._v(" Podrás ver la póliza al ingresar el rut del Cliente en el Inicio ")]),_c('li',[_vm._v("Refuerza con el Cliente los canales de atención")]),_c('li',[_vm._v(" Recuérdale al Cliente estar atento a las comunicaciones que le enviaremos para estar siempre informado del estado de su seguro ")]),_c('li',[_vm._v(" Recuerda revisar y adjuntar todos los documentos pendientes ")])]):_vm._e()],1)],1)],1)],1),(
          !(_vm.infoMPAY?.estado === 'PENDIENTE') ||
          !(_vm.data?.estado === 'PENDIENTE')
        )?[(_vm.infoMPAY?.estado === 'GENERADA')?_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex justify-space-around"},[_c('v-btn',{staticClass:"text-none px-6",attrs:{"color":"primary","rounded":"","disabled":!_vm.infoMPAY?.urlPoliza},on:{"click":function($event){return _vm.handlerDownloadDocument()}}},[_vm._v("Descargar póliza")]),(_vm.showBtnEntendido)?_c('v-btn',{staticClass:"text-none px-6",attrs:{"rounded":"","color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Entendido ")]):_vm._e()],1)])],1):(_vm.infoMPAY?.estado === 'ERROR' || _vm.data?.estado === 'ERROR')?_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex justify-center"},[(_vm.showBtnEntendido)?_c('v-btn',{staticClass:"text-none",attrs:{"rounded":"","color":"primary","width":"170"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Entendido ")]):_vm._e()],1)])],1):_vm._e()]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }