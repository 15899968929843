<template name="component-name">
  <v-form ref="form">
    <v-layout justify-center>
      <v-flex xs6 class="mr-4">
        <LabelComponent :text="docTypeDescription" />
        <v-text-field
          v-model="form.numero_documento"
          outlined
          dense
          v-mask="rutMask"
          :rules="[rules.required, rules.rut, rules.isValid]"
          name="rut_pagador"
        ></v-text-field>
      </v-flex>
      <v-flex xs6 class="ml-4">
        <LabelComponent text="Nombre*" />
        <v-text-field
          placeholder="Escriba un nombre..."
          name="nombre_pagador"
          v-model="form.nombre"
          :rules="[rules.required]"
          outlined
          dense
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout justify-center class="mt-1">
      <v-flex xs6 class="mr-4">
        <LabelComponent text="Primer apellido*" />
        <v-text-field
          name="fst_apellido_pagador"
          v-model="form.primer_apellido"
          :rules="[rules.required]"
          outlined
          dense
        ></v-text-field>
      </v-flex>
      <v-flex xs6 class="ml-4">
        <LabelComponent text="Segundo apellido*" />
        <v-text-field
          name="snd_apellido_pagador"
          :rules="[rules.required]"
          v-model="form.segundo_apellido"
          outlined
          dense
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout justify-center class="mt-1">
      <v-flex xs6 class="mr-4">
        <!-- CAMPO DATE SIN CALENDARIO -->
        <LabelComponent text="Fecha de nacimiento*" />
        <v-text-field
          v-model="form.fecha_nacimiento"
          :rules="[rules.required, rules.date, rules.date18]"
          placeholder="dd/mm/yyyy"
          v-mask="dateMask"
          outlined
          dense
          name="fecha_nacimiento_pagador"
        ></v-text-field>
      </v-flex>
      <v-flex xs6 class="ml-4">
        <LabelComponent :text="select_genero.label" />
        <v-select
          :items="select_genero.answers"
          v-model="form.genero"
          item-text="label"
          item-value="value"
          outlined
          dense
          name="genero_pagador"
        ></v-select>
      </v-flex>
    </v-layout>
    <v-layout justify-center class="mt-1">
      <v-flex xs6 class="mr-4">
        <LabelComponent text="Correo*" />
        <v-text-field
          v-model="form.correo"
          name="correo_pagador"
          :rules="[rules.required, rules.email]"
          outlined
          dense
        ></v-text-field>
      </v-flex>
      <v-flex xs6 class="ml-4">
        <LabelComponent text="Teléfono*" />
        <v-text-field
          v-model="form.telefono"
          name="telefono_pagador"
          outlined
          dense
          :rules="[rules.required, rules.telefono]"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout class="" justify-center>
      <v-flex xs6 class="mr-4">
        <LabelComponent text="Comuna*" />
        <v-autocomplete
          dense
          outlined
          :loading="loading_cities"
          :items="getCities?.answers ?? []"
          item-text="label"
          item-value="value"
          v-model="form.comuna"
          :rules="[rules.comunarequired]"
        >
        </v-autocomplete>
      </v-flex>
      <v-flex xs6 class="ml-4">
        <LabelComponent text="Dirección*" />
        <v-text-field
          name="dir_pagador"
          v-model="form.direccion"
          dense
          outlined
          :rules="[rules.required]"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout justify-center>
      <v-flex xs6 class="mr-4">
        <LabelComponent text="Número*" />
        <v-text-field
          v-model="form.numero"
          :rules="[rules.required]"
          type="number"
          name="numero_pagador"
          dense
          outlined
        ></v-text-field>
      </v-flex>
      <v-flex xs6 class="ml-4">
        <LabelComponent text="Departamento/Casa" />
        <v-text-field
          name="depto_pagador"
          v-model="form.piso"
          dense
          outlined
        ></v-text-field>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import { mapGetters } from "vuex";
// import DatePicker from "@/components/Inputs/DatePicker";
import LabelComponent from "@/components/Inputs/Label.vue";
import { dateMask } from "@/helpers/mask.js";
import {
  validarFecha,
  validarEmail,
  validarRutFormat,
  validarRut,
  validarFecha18,
} from "@/store/resources/validate.js";
import { rutMask } from "@/helpers/mask.js";
export default {
  props: {
    docType: {
      type: String,
      default: "",
    },
    docTypeDescription: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loading: false,
    showOther: false,
    payerBirthDate: "",
    form: {
      tipo_documento: "RUT",
      numero_documento: "",
      nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      fecha_nacimiento: "",
      genero: "",
      comuna: "",
      direccion: "",
      numero: "",
      piso: "",
      correo: "",
      telefono: "",
    },
    readonlyRut: false,
    valid: true,
    loading_cities: true,
    tab: "tab-1",
    data: {
      label: "Comuna",
      answers: [],
      selected: "",
      placeholder: "Comuna",
      rules: [(value) => !!value || "Comuna requerida."],
    },
    select_genero: {
      label: "Género",
      answers: [
        { label: "Masculino", value: "M" },
        { label: "Femenino", value: "F" },
      ],
      selected: "",
      placeholder: "Género",
      rules: [(value) => !!value || "Campo requerido."],
    },
    rules: {
      selectrequired: (value) => !!value || "Campo requerido.",
      comunarequired: (value) => !!value || "Comuna requerido.",
      required: (value) => !!value || "Campo requerido.",
      counter: (value) => value.length <= 20 || "Max 20 characters",
      email: (value) => validarEmail(value) || "Correo invalido.",
      telefono: (value) => value.length == 9 || "Teléfono inválido",
      rut: (value) => validarRutFormat(value) || "Formato de RUT invalido",
      date: (value) => validarFecha(value) || "Formato de fecha incorrecto",
      date18: (value) => validarFecha18(value) || "Debe ser mayor de edad",
      isValid: (value) =>
        validarRut(value) || "No se ha logrado reconocer el RUT",
    },
  }),

  created() {
    setTimeout(() => {
      this.loading_cities = false;
    }, 1500);
  },
  computed: {
    ...mapGetters("Proposal", ["getCities"]),
    rutMask: () => rutMask,
    dateMask: () => dateMask,
  },
  components: {
    LabelComponent,
  },
};
</script>
<style scoped>
.borderBottom {
  border-bottom: solid 1px #cac4d0;
}
.item-borderStyle {
  border-bottom: 3px solid #0077c8;
}
</style>
